import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import { Bounce, toast, ToastContainer } from 'react-toastify'
import { Button, Label, ModalBody } from 'reactstrap'
import {
  useGetDocsByProfileMutation,
  useGetVerificationsByIdQuery,
  useGetVerificationStatusQuery,
  useSaveVerificationStatusKPKMutation
} from 'src/services/kpk/verification'
import { PhotosContainer } from './Photos'
// import VerificationHistory from '../Common/EntitiesView/VerificationHistory'
// import BlockClient from '../Common/EntitiesView/BlockClient'

interface VerifModalKPK {
  isOpen: boolean
  verificationId: string
  closeModal: any
}

export const VerifModalKPK: FC<VerifModalKPK> = ({
  isOpen = false,
  verificationId,
  closeModal
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { data: statuses } = useGetVerificationStatusQuery({}, {})
  const { data: applicationByID } = useGetVerificationsByIdQuery(
    verificationId,
    { skip: !verificationId }
  )
  const [getProfileInfo] = useGetDocsByProfileMutation()
  const [saveStatus, { isLoading: loadingSave, error: decideError }] =
    useSaveVerificationStatusKPKMutation()

  const [currentStatus, setCurrentStatus] = useState('')
  const [applicationDocuments, setApplicationDocuments] = useState<any>([])
  const [comment, setComment] = useState('')
  const [showPhotos, setShowPhotos] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const toggle = () => setCollapsed((v) => !v)

  const getDocumentsList = async () => {
    try {
      await getProfileInfo(applicationByID?.profile_id)
        .unwrap()
        .then((res) => {
          console.log('doc by profile !!!! =>', res)
          setApplicationDocuments(res)
        })
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    if (isOpen && applicationByID) {
      getDocumentsList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, applicationByID])

  //сохранить с решением && без него
  const decide = async (decision: boolean) => {
    try {
      const reqBody = {
        decision_status: currentStatus,
        decision: decision,
        comment: comment
      }
      await saveStatus({ verificationId, payload: reqBody })
        .unwrap()
        .then((res) => {
          if (res.successful && decision) {
            toast.success(`${t('Success')}`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
              transition: Bounce
            })
            closeModal()
          }
        })
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    console.log('parent doc.list !!!! =>', applicationDocuments?.documents)
    console.log('parent total list !!!! =>', applicationDocuments?.total)
  }, [applicationDocuments])

  const header = (
    <div className='d-flex flex-row justify-content-between w-100 p-3'>
      <div className='modal-title'>
        <Link
          to={`/client-view?id=${applicationByID?.client_id}`}
          className='fw-medium text-decoration-underline'
        >
          {`${applicationByID?.surname} ${applicationByID?.name} ${applicationByID?.midname}`}
          <br />
          {`${applicationByID?.client_id}`}
        </Link>
      </div>
      <Button
        type='button'
        className='btn-close'
        onClick={closeModal}
        aria-label='Close'
      />
    </div>
  )

  if (collapsed) {
    return (
      <div
        id='kpk-verif-modal'
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={toggle}>
            <small className='fw-medium text-decoration-underline'>
              развернуть
            </small>
          </div>
          {header}
        </div>
      </div>
    )
  }

  if (!isOpen) return null

  console.log('photos parent =>', applicationDocuments?.documents)
  return (
    <>
      <ToastContainer />
      <div
        id='verif-modal-kpk'
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          top: 50,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={toggle}>
            <small className='fw-medium text-decoration-underline'>
              свернуть
            </small>
          </div>
          {header}
          <div className='border-bottom border-gray' />
          <ModalBody
            style={{
              overflow: 'auto',
              paddingBottom: 70
            }}
          >
            <Label className='form-label'>{t('Decision')}</Label>
            <select
              name={`decision-status`}
              id={'decision-status'}
              className='form-select form-select-sm mb-3'
              onChange={(e) => setCurrentStatus(e.target.value)}
              value={currentStatus}
            >
              <option key={'-'} value={'-'}>
                {'-'}
              </option>
              {Object.keys(statuses).map((key) => (
                <option key={key} value={key}>
                  {statuses[key]}
                </option>
              ))}
            </select>

            <div className='mb-3'>
              <textarea
                className='form-control'
                id='textarea-comment'
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </div>

            <div
              className='fs-7 text-info text-decoration-underline'
              role='button'
              onClick={() => {
                // if (!showPhotos) getApplicationPhotos()
                setShowPhotos((prev) => !prev)
              }}
            >
              {t('photos')}
            </div>

            {showPhotos && applicationDocuments && (
              <PhotosContainer
                photos={applicationDocuments.documents}
                total={applicationDocuments.total}
              />
            )}

            {decideError && (
              <div className='mt-2 mb-2'>
                <small className='text-danger'>
                  {/* @ts-ignore*/}
                  {t('Error')}: {decideError?.data?.detail}
                </small>
              </div>
            )}
          </ModalBody>

          <div
            className='border-top border-gray w-100 p-2 bg-light d-flex flex-column align-items-center'
            style={{
              position: 'absolute',
              bottom: 0
            }}
          >
            <div className='d-flex flex-row gap-2'>
              <Link
                to={`/application-view?id=${applicationByID?.application_id}`}
                className='btn btn-primary btn-sm'
              >
                <i className='ri-edit-2-line fs-7' />
              </Link>
              <Button
                color='primary'
                size='sm'
                disabled={loadingSave}
                onClick={() => {
                  decide(true)
                }}
              >
                {loadingSave ? t('Request_loading') : t('Save')}
              </Button>
              <Button
                color='primary'
                size='sm'
                disabled={loadingSave}
                onClick={() => {
                  decide(false)
                }}
              >
                {loadingSave ? t('Request_loading') : t('Save_no_decision')}
              </Button>
            </div>
            {location.pathname !== '/verification-applications' && (
              <Link
                to={`/verification-applications`}
                className='mt-2 text-decoration-underline'
              >
                <small>Вернуться к заявкам</small>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
