import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import List from '../List'
import { useGetCallsQuery } from 'src/services/calling'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const EntityContent = ({ entity, style, visible }) => {
  const { t } = useTranslation()
  const { data: list, error } = useGetCallsQuery(entity?.profile_id, {
    skip: entity?.profile_id == null || !visible,
  })
  const [filter, setFilter] = useState([])
  const [sortOrder, setSortOrder] = useState({
    created_at: 'desc',
    date_promise: 'desc',
    amount_promise: 'desc',
    username: 'desc',
    result: 'desc',
    contact_type: 'desc',
    reason: 'desc',
  })

  const fieldsDescription = {
    contact_type: { onClick: () => sortByAlpha('contact_type') },
    username: { label: 'Employee', onClick: () => sortByAlpha('username') },
    created_at: { isDateTime: true, onClick: () => sortByDate('created_at') },
    result: { onClick: () => sortByAlpha('result') },
    reason: { onClick: () => sortByAlpha('reason') },
    date_promise: {
      isDateTime: true,
      onClick: () => sortByDate('date_promise'),
    },
    amount_promise: {
      isAmount: true,
      onClick: () => sortByValue('amount_promise'),
    },
    comment: {},
  }

  useEffect(() => {
    if (list) {
      setFilter(list)
    }
  }, [list])

  const sortByAlpha = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc',
    }

    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return a[keyName].localeCompare(b[keyName], 'en', {
          sensitivity: 'base',
        })
      } else {
        return b[keyName].localeCompare(a[keyName], 'en', {
          sensitivity: 'base',
        })
      }
    })

    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const sortByValue = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc',
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return a[keyName] - b[keyName]
      } else {
        return b[keyName] - a[keyName]
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const sortByDate = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc',
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return new Date(a[keyName]) - new Date(b[keyName])
      } else {
        return new Date(b[keyName]) - new Date(a[keyName])
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  return (
    <div>
      {error && <DangerAlert error={error} />}
      {list?.length ? (
        <List
          list={filter}
          fieldsDescription={fieldsDescription}
          style={style}
        />
      ) : (
        <div style={{ marginBottom: '10px' }}>
          <dl className="row mb-0">
            <small>{t('No_communications')}</small>
          </dl>
        </div>
      )}
    </div>
  )
}

export default EntityContent
