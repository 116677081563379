import { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { UseQueryHookArgs } from 'src/services/api'
import {
  // useDownloadUsersMutation,
  useGetUsersQuery
} from 'src/services/keycloak'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import List from 'src/Components/Common/EntitiesView/List'
import { LIST_LIMIT } from 'src/client'
import MetaBar from 'src/Components/Common/MetaBar'

export const Users = () => {
  const { t } = useTranslation()
  const title = t('Users')
  document.title = title

  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetUsersQuery>
  >({})

  const {
    data: users,
    error: listError,
    isLoading: listLoading
  } = useGetUsersQuery({
    limit: LIST_LIMIT,
    offset: 0,
    ...fieldsFilter
  })

  // const [downloadUsers] = useDownloadUsersMutation()

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (!users || listLoading) {
    return <LoadingPage backLink={'/products'} title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar createLink='/user-edit' />
          <FieldsFilter
            fields={{
              id: { label: 'id' },
              username: { label: 'login' },
              surname: { label: 'surname' },
              name: { label: 'username' },
              midname: { label: 'midname' },
              state: { label: 'state' },
              blocked_cause: { label: 'blocked_cause' },
              phone: { label: 'phone' },
              email: { label: 'email' },
              company: { label: 'company' }
            }}
            applyFilter={(values: typeof fieldsFilter) =>
              // FieldsFilter runs applyFilter function on reset instead of resetFilter
              setFieldsFilter(values)
            }
            resetFilter={() => setFieldsFilter({})}
          />
          <List
            list={users}
            fieldsDescription={{
              id: {
                label: ' ',

                mappingValue: () => <i className='ri-eye-line fs-15' />,
                isLink: true,
                mapLinkValue: (item: (typeof users)[0]) =>
                  `/user-view?id=${item.username}`
              },
              username: { label: 'login' },
              surname: {},
              name: { label: 'username' },
              midname: {},
              state: {},
              blocked_cause: {},
              phone: {},
              email: {},
              company: {}
            }}
          />
          {/* <Button
            className='mt-2 d-flex align-items-center gap-1'
            color='success'
            onClick={() =>
              downloadUsers({
                limit: LIST_LIMIT,
                offset: 0,
                ...fieldsFilter
              })
            }
          >
            <i className='bx bx-spreadsheet' />
            <span>Скачать .CSV</span>
          </Button> */}
        </CardBody>
      </Card>
    </div>
  )
}
