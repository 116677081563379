import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import Select from 'react-select'
import Client from 'src/client'
import { mapBooleanStringToBoolean } from 'src/utils'
import { toast } from 'react-toastify'
import { AcceptModal } from './Components/deleteModal'

import { getEntities } from 'src/store/actions'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Flatpickr from 'react-flatpickr'
import { CollectorsGroup } from './Components/collectorsGroup'
import { useGetPromotionsQuery } from 'src/services/promotions'
import { useNavigate } from 'react-router-dom'

export const SegmentEdit = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [entity, setEntity] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [error, setError] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const [filterCollectorName, setFilterCollectorName] = useState('')
  const [filterGroupName, setFilterGroupName] = useState('')
  const [collectors, setCollectors] = useState([])
  const [groups, setGroups] = useState([])
  const [collectorsByStage, setCollectorsByStage] = useState([])
  const [tagsList, setTagsList] = useState([])
  const [selectedTags, setSelectedTags] = useState(null)
  const [show, setShow] = useState(false)

  const navigate = useNavigate()

  const backlink = '/segments'

  const selectAllOption = { value: 'selectAll', label: 'Выбрать все' }
  const { entityLoaded, entityLoading, updateLoading, createLoading } =
    useSelector((state) => ({
      entityLoaded: state.entities[Client.ENTITY_TYPE.SEGMENTS].entity,
      entityLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].entityLoading,
      entityError: state.entities[Client.ENTITY_TYPE.SEGMENTS].entityError,
      updateError: state.entities[Client.ENTITY_TYPE.SEGMENTS].updateError,
      createError: state.entities[Client.ENTITY_TYPE.SEGMENTS].createError,
      updateLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].updateLoading,
      createLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].createLoading,
    }))

  const customSelector = {
    container: (provided) => ({
      ...provided,
      width: '300px', // Настройте ширину контейнера по вашему усмотрению
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc', // Настройте границу контрола
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : 'white', // Настройте цвет фона опций
      color: state.isSelected ? 'white' : 'black', // Настройте цвет текста опций
    }),
  }

  const { data: promotions } = useGetPromotionsQuery()

  useEffect(() => {
    console.log(tagsList, '!!!')
    if (tagsList.length === 0) {
      Client.getLoansTags()
        .then((res) => {
          setTagsList(
            res.map((item) => ({
              value: item.tag,
              label: item.tag,
              id: item.id,
            })),
          )
        })
        .catch((err) => console.log(err, '!!!'))
    }
  }, [tagsList])

  const inputInitials = {
    name: { label: 'name', initial: '', placeholder: '' },
    min_dpd: { label: 'min_dpd', initial: '', placeholder: '' },
    max_dpd: { label: 'max_dpd', initial: '', placeholder: '' },
    min_score: { label: 'min_score', initial: '', placeholder: '' },
    max_score: { label: 'max_score', initial: '', placeholder: '' },
    ignore_tags_mismatch: {
      label: 'Игнорировать конфликт тегов',
      initial: 'true',
      options: [
        { name: 'Да', value: 'true' },
        { name: 'Нет', value: 'false' },
      ],
    },
    tags: { label: 'tags', multi: true },
    accrual_stop: {
      label: 'Stopping the accrual of interest and penalties',
      defaultValueSelect: t('Ignore'),
      options: [
        { name: t('yes'), value: 'true' },
        { name: t('no'), value: 'false' },
      ],
    },
    promotion_id: {
      label: 'Promotion',
      options:
        promotions?.map((promotion) => ({
          name: promotion.promo_name,
          value: promotion.id,
        })) || [],
    },
    is_auto: {
      label: 'is_auto',
      options: [
        { name: t('Manual'), value: 'false' },
        { name: t('Auto'), value: 'true' },
      ],
    },
    request_type: {
      label: t('Loan_type'),
      options: [
        { name: t('All'), value: 0 },
        { name: t('Primary'), value: 1 },
        { name: t('Secondaries'), value: 2 },
      ],
    },
    auto_sale: {
      label: 'auto_sale',
      options: [
        { name: t('no'), value: 'false' },
        { name: t('yes'), value: 'true' },
      ],
    },
    ip_count: { label: 'ip_count', initial: '', placeholder: '', show: true },
    debt_load: { label: 'debt_load', initial: '', placeholder: '', show: true },
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    console.log('id => ', id)
    setId(id || null)
    if (id) {
      //edit mode
      setPageReady(false)
      dispatch(getEntities(Client.ENTITY_TYPE.SEGMENTS, id))
      Client.getStageCollectors(id).then((res) => {
        setCollectorsByStage(res)
      })
      Client.getAllCollectors().then((res) => {
        setCollectors(
          res.map((collector) => ({
            ...collector,
            FIO: `${collector.surname} ${collector.name} ${collector.midname}`.toLowerCase(),
          })),
        )
      })
      Client.getAllGroups().then((res) => {
        Promise.allSettled(
          res.map((item) => Client.getGroupCollectors(item.id)),
        ).then((groups) => {
          let newGroups = []
          res.forEach((group, index) => {
            newGroups.push({
              FIO: group.name,
              items: groups[index].value,
              id: group.id,
            })
          })
          setGroups(newGroups)
          setPageReady(true)
        })
      })
    } else {
      //create mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] = inputInitials[key].initial
      })
      setInitialFormValues(initialFormValues)
      const fp_begin_date = document.querySelector('#validation-begin_date')
      const fp_end_date = document.querySelector('#validation-end_date')
      if (fp_begin_date) {
        //console.log('fp_begin_date=>', fp_begin_date._flatpickr);
        fp_begin_date._flatpickr.setDate(inputInitials['begin_date'].initial)
      }
      if (fp_end_date) {
        //console.log('fp_begin_date=>', fp_end_date._flatpickr);
        fp_end_date._flatpickr.setDate(inputInitials['end_date'].initial)
      }
      setPageReady(true)
    }
  }, [dispatch, props.location])

  useEffect(() => {
    if (id && entityLoading === false) {
      //edit mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] =
          entityLoaded &&
          entityLoaded[key] !== null &&
          entityLoaded[key] !== undefined
            ? entityLoaded[key].toString()
            : ''
      })
      console.log('edit mode, init fields =>', initialFormValues)
      setInitialFormValues(initialFormValues)
      setEntity(entityLoaded)
      const fp_begin_date = document.querySelector('#validation-begin_date')
      const fp_end_date = document.querySelector('#validation-end_date')
      //console.log('edit mode, init fields =>', initialFormValues, fp_begin_date, fp_end_date);
      if (fp_begin_date) {
        //console.log('fp_begin_date=>', fp_begin_date._flatpickr, initialFormValues.begin_date);
        fp_begin_date._flatpickr.setDate(initialFormValues.begin_date)
      }
      if (fp_end_date) {
        //console.log('fp_end_date=>', fp_end_date._flatpickr, initialFormValues.end_date);
        fp_end_date._flatpickr.setDate(initialFormValues.end_date)
      }
      setPageReady(true)
    }
  }, [entityLoaded, entityLoading, id])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...initialFormValues,
    },
    validationSchema: Yup.object({
      /*
      notify_channel: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('notify_channel')}`),
      subject: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('subject')}`),
      template_text: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('template_text')}`),
      sender_id: Yup.string().test('non-empty', t('Cannot_be_empty'), name => name && name.trim().length > 0).required(`${t("Required")}: ${t('sender_id')}`),
      */
      promotion_id: Yup.string().nullable(),
      ignore_tags_mismatch: Yup.string(),
      is_auto: Yup.string().when('promotion_id', {
        is: (promotion_id) => promotion_id != null,
        then: Yup.string().required(t('Field is required')),
        otherwise: Yup.string().nullable(),
      }),
    }),
    onSubmit: (data) => {
      console.log(data, '!!!!!!!!')
      if (data.min_dpd === '' || data.min_dpd === undefined) {
        data.min_dpd = null
      }
      if (data.max_dpd === '' || data.max_dpd === undefined) {
        data.max_dpd = null
      }
      if (data.min_score === '' || data.min_score === undefined) {
        data.min_score = null
      }
      if (data.max_score === '' || data.max_score === undefined) {
        data.max_score = null
      }
      if (data.tags) {
        delete data.tags
      }
      if (!data.ignore_tags_mismatch) {
        data.ignore_tags_mismatch = true
      }
      if (data.ignore_tags_mismatch === 'true') {
        data.ignore_tags_mismatch = true
      }
      if (data.ignore_tags_mismatch === 'false') {
        data.ignore_tags_mismatch = false
      }
      data.auto_sale === 'true'
        ? (data.auto_sale = true)
        : (data.auto_sale = false)
      data.ip_count.length === 0
        ? (data.ip_count = null)
        : Number(data.ip_count)
      data.debt_load.length === 0
        ? (data.debt_load = null)
        : Number(data.debt_load)
      if (data.accrual_stop === '') data.accrual_stop = null
      if (data.accrual_stop === 'true') data.accrual_stop = true
      if (data.accrual_stop === 'false') data.accrual_stop = false
      const { promotion_id, is_auto, ...values } = data
      if (id) {
        const body = {
          values: { ...values, request_type: Number(values.request_type) },
          tags: selectedTags,
          user_ids: collectorsByStage.map((collector) => collector.id),
        }
        Client.updateStage(id, body, {
          is_auto: mapBooleanStringToBoolean(is_auto),
          promotion_id,
        })
          .then(() => {
            setSuccessful('true')
            toast.success(t('Operation success'), {
              onClose: () => navigate(backlink),
            })
          })
          .catch((error) => setError(error))
      } else {
        const bodyCreate = {
          values: { ...values, request_type: Number(values.request_type) },
          tags: selectedTags,
        }
        Client.createStage(bodyCreate, {
          is_auto: mapBooleanStringToBoolean(is_auto),
          promotion_id,
        })
          .then(() => {
            setSuccessful('true')
            toast.success(t('Operation success'), {
              onClose: () => navigate(backlink),
            })
          })
          .catch((error) => setError(error))
      }
    },
  })

  const handleChangeTags = (selectedValues) => {
    const selectedOptionValues = selectedValues.map((option) => option.value)
    if (selectedOptionValues.includes('selectAll')) {
      setSelectedTags(tagsList.map((option) => option.id))
    } else {
      setSelectedTags(selectedValues.map((option) => option.id))
    }
  }

  useEffect(() => {
    if (entityLoaded?.tags) {
      setSelectedTags(
        Object.keys(entityLoaded.tags).map((option) => Number(option)),
      )
    }
  }, [entityLoaded])

  const title = `${t('Segments')}: ${id ? t('Edit') : t('Create')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={backlink} entity={entity} />

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={backlink} title={title} />
  }

  const onChangeCollector = (collector) => {
    const index = collectorsByStage.findIndex(
      (item) => collector.id === item.id,
    )
    if (index > -1) {
      setCollectorsByStage([
        ...collectorsByStage.slice(0, index),
        ...collectorsByStage.slice(index + 1, collectorsByStage.length),
      ])
    } else {
      setCollectorsByStage([...collectorsByStage, collector])
    }
  }

  const toggle = () => {
    setShow(!show)
  }

  return (
    <>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            <div className="d-flex gap-2">
              {metaBar}
              <Button
                className="btn-icon"
                color="primary"
                style={{ backgroundColor: '#405189', width: '100px' }}
                onClick={() => setShow(true)}
              >
                <i class="ri-delete-bin-line label-icon align-middle fs-16 me-2"></i>
                {t('Delete')}
              </Button>
            </div>
            <AcceptModal show={show} toggle={toggle} />
            <Form
              className="needs-validation mt-4"
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                {Object.keys(inputInitials).map((fieldKey) => {
                  // console.log(`validation.values[${fieldKey}] => `, validation.values[fieldKey]);
                  const htmlForId = `validation-${fieldKey}`
                  if (inputInitials[fieldKey].show) {
                    return (
                      <>
                        {validation.values.auto_sale === 'true' ? (
                          <Col
                            className={
                              inputInitials[fieldKey].fullWidth
                                ? 'col-12'
                                : 'col-auto'
                            }
                            key={`${fieldKey}`}
                          >
                            <FormGroup className="mb-3">
                              <Label htmlFor={htmlForId}>
                                {t(inputInitials[fieldKey].label)}
                              </Label>
                              <Input
                                name={`${fieldKey}`}
                                placeholder={
                                  inputInitials[fieldKey].placeholder
                                }
                                type="number"
                                className="form-control form-control-sm"
                                id={htmlForId}
                                onChange={(e) => {
                                  validation.setFieldValue(
                                    inputInitials[fieldKey].label,
                                    Number(e.target.value),
                                  )
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values[fieldKey] || ''}
                                invalid={
                                  !!(
                                    validation.touched[fieldKey] &&
                                    validation.errors[fieldKey]
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        ) : null}
                      </>
                    )
                  }
                  return (
                    <Col
                      className={
                        inputInitials[fieldKey].fullWidth
                          ? 'col-12'
                          : 'col-auto'
                      }
                      key={`${fieldKey}`}
                    >
                      <FormGroup className="mb-3">
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        {fieldKey === 'promotion_id' ? (
                          <select
                            name={fieldKey}
                            id={fieldKey}
                            className="form-select form-select-sm"
                            onChange={(e) => {
                              validation.setFieldValue('is_auto', 'false')
                              validation.handleChange(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                            disabled={validation.values.auto_sale === 'true'}
                          >
                            <option value={0}>-</option>
                            {inputInitials[fieldKey].options.map((option) => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : fieldKey === 'is_auto' ? (
                          <select
                            name={fieldKey}
                            id={fieldKey}
                            className="form-select form-select-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                            disabled={
                              !validation.values.promotion_id ||
                              inputInitials.promotion_id.options.length === 0 ||
                              validation.values.auto_sale === 'true'
                            }
                          >
                            <option hidden disabled selected></option>
                            {inputInitials[fieldKey].options.map((option) => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : fieldKey === 'auto_sale' ? (
                          <select
                            name={fieldKey}
                            id={fieldKey}
                            className="form-select form-select-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                            disabled={validation.values.promotion_id > 0}
                          >
                            <option hidden disabled selected></option>
                            {inputInitials[fieldKey].options.map((option) => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : fieldKey === 'accrual_stop' ? (
                          <select
                            name={fieldKey}
                            id={fieldKey}
                            className="form-select form-select-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                            disabled={
                              inputInitials[fieldKey].options.length === 0
                            }
                          >
                            <option selected hidden value={null}>
                              {inputInitials[fieldKey].defaultValueSelect}
                            </option>
                            {inputInitials[fieldKey].options.map((option) => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : inputInitials[fieldKey].options ? (
                          <select
                            name={fieldKey}
                            id={fieldKey}
                            className="form-select form-select-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                            disabled={
                              inputInitials[fieldKey].options.length === 0
                            }
                          >
                            <option hidden disabled selected></option>
                            {inputInitials[fieldKey].options.map((option) => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : inputInitials[fieldKey].date ? (
                          <Flatpickr
                            name={`${fieldKey}`}
                            id={htmlForId}
                            className="form-control form-control-sm"
                            options={{
                              dateFormat: 'Y-m-d',
                              defaultDate: [
                                validation.values[fieldKey] || null,
                              ],
                              onChange: (selectedDates, dateStr) => {
                                console.log('dateStr', dateStr)
                                validation.setFieldValue(fieldKey, dateStr)
                              },
                            }}
                            value={validation.values[fieldKey]}
                          />
                        ) : inputInitials[fieldKey].multi ? (
                          <Select
                            isMulti
                            styles={customSelector}
                            options={[selectAllOption, ...tagsList]}
                            onChange={handleChangeTags}
                            defaultValue={
                              entityLoaded?.tags
                                ? Object.keys(entityLoaded.tags).map(
                                    (option) => ({
                                      value: entityLoaded.tags[option],
                                      label: entityLoaded.tags[option],
                                      id: Number(option),
                                    }),
                                  )
                                : []
                            }
                          />
                        ) : fieldKey === 'name' ? (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={inputInitials[fieldKey].placeholder}
                            type="text"
                            className="form-control form-control-sm"
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        ) : (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={inputInitials[fieldKey].placeholder}
                            type="number"
                            className="form-control form-control-sm"
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        )}
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type="invalid">
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {collectors.length > 0 && id && (
                <div style={{ width: '500px', marginBottom: '10px' }}>
                  <Input
                    onChange={(evv) =>
                      setFilterCollectorName(evv.target.value.toLowerCase())
                    }
                    style={{ marginBottom: '10px' }}
                    placeholder={t('Enter user name')}
                    className="form-control form-control-sm"
                  />
                  <Input
                    onChange={(evv) =>
                      setFilterGroupName(evv.target.value.toLowerCase())
                    }
                    style={{ marginBottom: '10px' }}
                    placeholder={t('Enter group name')}
                    className="form-control form-control-sm"
                  />
                  <h5>{t('Groups')}</h5>
                  {groups
                    .filter((group) =>
                      group.FIO.toLowerCase().includes(filterGroupName),
                    )
                    .map((group) => (
                      <CollectorsGroup
                        setCollectorsByStage={setCollectorsByStage}
                        filterCollectorName={filterCollectorName}
                        onChangeCollector={onChangeCollector}
                        selectedCollectors={collectorsByStage}
                        key={`${group.FIO}-${group.id}`}
                        items={group.items}
                        name={group.FIO}
                        id={group.id}
                      />
                    ))}
                  <h5 style={{ marginTop: '15px' }}>{t('Collectors')}</h5>
                  {collectors
                    .filter((col) => col.FIO.includes(filterCollectorName))
                    .map((collector) => (
                      <Label
                        style={{
                          verticalAlign: 'baseline',
                          display: 'block',
                          lineHeight: '21px',
                          fontSize: '14px',
                          marginBottom: '10px',
                        }}
                        key={collector.id}
                        className="form-check-label"
                      >
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={Boolean(
                            collectorsByStage.find(
                              (item) => collector.id === item.id,
                            ),
                          )}
                          id={collector.id}
                          style={{
                            width: '21px',
                            height: '21px',
                            marginRight: '15px',
                            cursor: 'pointer',
                          }}
                          onChange={() => onChangeCollector(collector)}
                        />
                        <span>
                          {collector.surname} {collector.name}{' '}
                          {collector.midname}
                        </span>
                      </Label>
                    ))}
                </div>
              )}

              {error && (
                <Alert color="warning">
                  <strong>{JSON.stringify(error)}</strong>
                </Alert>
              )}
              {successful && (
                <Alert color="success">
                  <strong> {t('Operation success')} </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className="text-nowrap"
                  color="primary"
                  style={{ backgroundColor: '#405189' }}
                  type="submit"
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
