import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'
import Client from 'src/client'

import { deleteEntities } from 'src/store/actions'
import { useGetPromocodesQuery } from 'src/services/promocodes'

const headers = {
  id: {},
  sale: { isAmount: true },
  promocode: {},
  begin_date: { label: 'promocode_start_date', isDate: true },
  end_date: { label: 'promocode_end_date', isDate: true },
  limitless: {
    label: 'type',
    mappingValue: (value) => (value ? 'Многоразовый' : 'Ограниченный'),
  },
  available_usages: { label: 'Available usages' },
  total_usage_limit: { label: 'Total usage limit' },
}

const actionsEnabled = true
const enableDelete = false

export const Promocodes = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    data: list,
    isLoading: listLoading,
    error: listError,
  } = useGetPromocodesQuery({ limit: 50, offset: 0 })

  const archive = (settingsId) => {
    dispatch(deleteEntities(settingsId, Client.ENTITY_TYPE.PROMOCODES))
  }

  console.log('list =>', list)
  const title = t('Promocodes')
  document.title = title
  const metaBar = <MetaBar createLink={'/promocodes-create'} />

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  if (listLoading) {
    return <LoadingPage title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            <div className="table-responsive table-card mt-4">
              <Table hover className="table-sm align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th className="ps-3" key={header} scope="col">
                        {t(headers[header].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope="col">{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.length > 0 &&
                    list.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/promocodes-view?id=${entity.id}`}
                              className="fw-medium"
                            >
                              <i className="ri-eye-line fs-15" />
                            </Link>
                          </td>
                          {Object.keys(headers).map((fieldName) => {
                            if (fieldName === 'id')
                              return (
                                <td className="ps-3" key={fieldName}>
                                  {entity.id}
                                </td>
                              )
                            return (
                              <td className="ps-3" key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers,
                                )}
                              </td>
                            )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className="hstack gap-3 flex-wrap">
                                <Link
                                  to={`/promocodes-edit?id=${entity.id}`}
                                  className="link-success fs-15"
                                >
                                  <i className="ri-edit-2-line"></i>
                                </Link>
                                {enableDelete && (
                                  <Link
                                    to="#"
                                    onClick={() => archive(entity.id)}
                                    className="link-danger fs-15"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
