import { useEffect, useState } from 'react'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { useTranslation } from 'react-i18next'
import MetaBar from 'src/Components/Common/MetaBar'
import {
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  Container,
  FormGroup,
  Spinner,
  Alert,
  Button,
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  useGetWriteoffTypesQuery,
  useSetWriteoffMutation,
  useSetProlongationMutation,
  useGetLoanQuery,
  useSignDocsPromotionMutation,
} from 'src/services/loans'
import { useSearchParams } from 'react-router-dom'
import { AskAboutAction } from 'src/Components/Common/AskAboutAction'

export const LoanEdit = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [id, setId] = useState<string>()
  const [showModal, setShowModal] = useState(false)
  const [days, setDays] = useState<number>(0)
  const [writeoffLoading, setWriteoffLoading] = useState(false)

  const {
    data: loan,
    isLoading,
    isError,
    error,
  } = useGetLoanQuery(id!, {
    skip: id == null,
  })
  const { data: writeoffTypes } = useGetWriteoffTypesQuery()
  const [signDocsPromotion] = useSignDocsPromotionMutation()

  const [
    setWriteoff,
    {
      isSuccess: isSetWriteoffSuccess,
      error: setWriteoffError,
      isLoading: isSetWriteoffLoading,
      isError: isSetWriteoffError,
    },
  ] = useSetWriteoffMutation()
  const [
    setProlongation,
    {
      isSuccess: isSetProlongationSuccess,
      error: setProlongationError,
      isLoading: isSetProlongationLoading,
      isError: isSetProlongationError,
    },
  ] = useSetProlongationMutation()

  const toggle = () => setShowModal(false)

  useEffect(() => {
    const maybeId = searchParams.get('id')
    if (maybeId) setId(maybeId)
  }, [searchParams])

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: 0,
      writeoff_type: 0,
    },
    validationSchema: Yup.object({
      amount: Yup.number(),
      writeoff_type: Yup.number(),
    }),
    onSubmit: (values) => {
      if (values.writeoff_type === 8) {
        signDocsPromotion({ profile_id: loan!.profile_id })
        setWriteoffLoading(true)
        setTimeout(() => {
          setWriteoff({ loanId: id!, body: values }).then((res) => {
            setShowModal(false)
            setWriteoffLoading(false)
          })
        }, 1500)
      } else {
        setWriteoff({ loanId: id!, body: values }).then((res) =>
          setShowModal(false),
        )
      }
    },
  })

  const title = t('Edit loan')

  const loading = (
    <div className="p-5 text-center">
      <Spinner color="primary">{t('Loading...')}</Spinner>
    </div>
  )

  if (isError) {
    return (
      <>
        <div className="page-content">
          <Container fluid={true}>
            <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
            <Alert color="warning">
              <strong> {isError && JSON.stringify(error)} </strong>
            </Alert>
          </Container>
        </div>
      </>
    )
  }
  if (isLoading) {
    return (
      <>
        <div className="page-content">
          <Container fluid={true}>
            <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
            {loading}
          </Container>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="page-content">
        <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
        <Card>
          <CardBody>
            <MetaBar
              backLink={`/loan-view?id=${loan?.id}`}
              entity={loan}
              createLink={undefined}
              editLink={undefined}
              buttons={undefined}
              disableBorder={undefined}
            />
            <Container fluid={true} className="mt-4">
              <Row>
                <Col md="4" className="mb-2">
                  <FormGroup className="mb-3">
                    <Label htmlFor={'prolongation_days'}>
                      {t('prolongation_days')}
                    </Label>
                    <Input
                      name={`prolongation_days`}
                      type="number"
                      className="form-control form-control-sm"
                      id={'prolongation_days'}
                      onChange={(e) => setDays(Number(e.target.value))}
                      value={days || 0}
                    />
                  </FormGroup>
                  <Button
                    color="success"
                    onClick={() =>
                      setProlongation({ loanId: id!, body: { days: days } })
                    }
                    disabled={isSetProlongationLoading}
                  >
                    {t('Update')}
                  </Button>
                </Col>
                <Col md="8">
                  <Form
                    id="formElemPromo"
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor={'writeoff_type'}>
                            {t('writeoff_type')}
                          </Label>
                          <select
                            name={`writeoff_type`}
                            id={'writeoff_type'}
                            className="form-select form-select-sm"
                            onChange={(e) => {
                              if (Number(e.target.value) === 8) {
                                validation.setFieldValue('amount', 0)
                              }
                              validation.setFieldValue(
                                'writeoff_type',
                                Number(e.target.value),
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.writeoff_type || ''}
                          >
                            {' '}
                            <option hidden disabled />
                            {writeoffTypes?.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor={'amount'}>
                            {t('writeoff_amount')}
                          </Label>
                          <Input
                            name={`amount`}
                            type="number"
                            className="form-control form-control-sm"
                            id={'amount'}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ''}
                            disabled={validation.values.writeoff_type === 8}
                            invalid={
                              !!(
                                validation.touched.amount &&
                                validation.errors.amount
                              )
                            }
                          />
                        </FormGroup>
                        <Button
                          color="success"
                          type="button"
                          onClick={() => setShowModal(true)}
                          disabled={isSetWriteoffLoading}
                        >
                          {t('Update')}
                        </Button>
                      </Col>
                    </Row>

                    <AskAboutAction
                      cancelAction={() => setShowModal(false)}
                      sureAction={() => validation.handleSubmit()}
                      isOpen={showModal}
                      title="The loan debt will be written off. Has the terms of the loan promotion been verified?"
                      toggle={toggle}
                      loading={writeoffLoading}
                    />
                    {isSetWriteoffError && (
                      <Alert color="warning">
                        <strong>{JSON.stringify(setWriteoffError)}</strong>
                      </Alert>
                    )}
                    {isSetWriteoffSuccess && (
                      <Alert color="success">
                        <strong> {t('Operation success')} </strong>
                      </Alert>
                    )}
                  </Form>
                </Col>

                {isSetProlongationError && (
                  <Alert color="warning">
                    <strong>{JSON.stringify(setProlongationError)}</strong>
                  </Alert>
                )}

                {isSetProlongationSuccess && (
                  <Alert color="success">
                    <strong> {t('Operation success')} </strong>
                  </Alert>
                )}
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
