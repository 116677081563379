import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'
import flatpickr from 'flatpickr'

import {
  formatDate,
  formatAPIDate,
  formatAPIDateTime,
  formatDateTime,
  formatUIDate,
} from '../../utils'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from 'reactstrap'

import * as Yup from 'yup'
import { useFormik } from 'formik'

const FieldsFilter = (props) => {
  const { t, i18n } = useTranslation()
  const { filter, fields, applyFilter, resetFilter } = props

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...filter },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      let filterToApply = { ...values }
      Object.keys(values).forEach((fieldKey) => {
        if (values[fieldKey] === '-') delete filterToApply[fieldKey]
      })
      applyFilter && applyFilter(filterToApply)
    },
  })

  useEffect(() => {
    if (!props.filter) return
    Object.keys(fields).forEach((fieldKey, i) => {
      if (fields[fieldKey].date && props.filter[fieldKey]) {
        const htmlForId = `validation-${fieldKey}`
        const fp = document.querySelector(`#${htmlForId}`)
        if (fp) {
          console.log('fp=>', fp._flatpickr)
          fp._flatpickr.setDate(formatDate(props.filter[fieldKey]))
        }
      }
    })
  }, [props.filter])

  return (
    <Form
      className="needs-validation p-3"
      onSubmit={(e) => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      <Row>
        {Object.keys(fields).map((fieldKey, i) => {
          const htmlForId = `validation-${fieldKey}`
          if (
            fieldKey === 'group_delimiter' ||
            fieldKey === 'group_delimiter_2'
          )
            return <Row key={`${fieldKey}`} />
          return (
            <Col className="col-xxl-auto col-xl-3 col-4" key={`${fieldKey}`}>
              <FormGroup className="mb-3">
                <Label htmlFor={htmlForId}>
                  {t(fields[fieldKey].label || fieldKey)}
                </Label>
                {fields[fieldKey].options && fields[fieldKey].options.length ? (
                  <select
                    name={`${fieldKey}`}
                    id={htmlForId}
                    className="form-select form-select-sm"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values[fieldKey] || ''}
                  >
                    <option key={'-'}>{'-'}</option>
                    {fields[fieldKey].options.map((option) => (
                      <option key={option}>{option}</option>
                    ))}
                  </select>
                ) : fields[fieldKey].optionsValue &&
                  fields[fieldKey].optionsValue.length ? (
                  <select
                    name={`${fieldKey}`}
                    id={htmlForId}
                    className="form-select form-select-sm"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values[fieldKey] || ''}
                  >
                    <option key={'-'}>{'-'}</option>
                    {fields[fieldKey].optionsValue.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                ) : fields[fieldKey].date ? (
                  <Flatpickr
                    name={`${fieldKey}`}
                    id={htmlForId}
                    className="form-control form-control-sm"
                    options={{
                      dateFormat: 'd-m-Y',
                      defaultDate: [], //[formatDate(validation.values[fieldKey]],
                      onChange: (selectedDates, dateStr) => {
                        console.log(
                          'selectedDate, dateStr',
                          selectedDates,
                          dateStr,
                        )
                        validation.setFieldValue(
                          fieldKey,
                          formatAPIDate(selectedDates[0]),
                        )
                      },
                    }}
                  />
                ) : fields[fieldKey].datetime ? (
                  <Flatpickr
                    name={`${fieldKey}`}
                    id={htmlForId}
                    className="form-control form-control-sm"
                    options={{
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: 'd-m-Y H:i',
                      defaultDate: [], //[formatDate(validation.values[fieldKey]],
                      onChange: (selectedDates, dateStr) => {
                        console.log(
                          'selectedDate, dateStr',
                          selectedDates,
                          dateStr,
                        )
                        validation.setFieldValue(
                          fieldKey,
                          formatAPIDateTime(selectedDates[0]),
                        )
                      },
                    }}
                  />
                ) : (
                  <Input
                    name={`${fieldKey}`}
                    placeholder={fields[fieldKey].placeholder}
                    type="text"
                    className="form-control form-control-sm"
                    id={htmlForId}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values[fieldKey] || ''}
                    invalid={
                      !!(
                        validation.touched[fieldKey] &&
                        validation.errors[fieldKey]
                      )
                    }
                  />
                )}
                {validation.touched[fieldKey] && validation.errors[fieldKey] ? (
                  <FormFeedback type="invalid">
                    {validation.errors[fieldKey]}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          )
        })}
      </Row>
      <Row className="mb-3">
        <div className="hstack gap-1">
          <Button
            color="primary"
            style={{ backgroundColor: '#405189' }}
            type="submit"
          >
            {t('Apply')}
          </Button>
          <Button
            color="primary"
            style={{ backgroundColor: '#405189' }}
            type="submit"
            onClick={() => {
              Object.keys(fields).forEach((fieldKey) => {
                if (
                  fields[fieldKey].options &&
                  fields[fieldKey].options.length
                ) {
                  validation.setFieldValue(fieldKey, '-')
                } else if (fields[fieldKey].date) {
                  const htmlForId = `validation-${fieldKey}`
                  const fp = document.querySelector(`#${htmlForId}`)
                  if (fp) {
                    fp._flatpickr.setDate(null)
                  }
                  validation.setFieldValue(fieldKey, '')
                } else {
                  validation.setFieldValue(fieldKey, '')
                }
              })
              applyFilter && applyFilter({})
            }}
          >
            {t('Reset')}
          </Button>
        </div>
      </Row>
    </Form>
  )
}

export default FieldsFilter
