import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import EntityInfo from 'src/Components/Common/EntityInfo'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'
import Related from './Related'

import { getApplications } from 'src/store/actions'

import { Row, Card, CardBody, Button, Alert } from 'reactstrap'
import { AskAboutAction } from 'src/Components/Common/AskAboutAction'
import { Code } from 'src/Components/Common/Code'

const columnsDescription = [
  ['application_status'],
  ['new_line'],
  ['requested_amount', 'requested_period'],
  ['approved_amount', 'approved_period'],
  ['signed_amount', 'signed_period']
]

export const ApplicationView = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)

  const [paramError, setParamError] = useState(null)
  const { application, listLoading, listError } = useSelector((state) => ({
    application:
      state.applications.list && state.applications.list.length
        ? state.applications.list[0]
        : null,
    listLoading: state.applications.listLoading,
    listError: state.applications.listError,
    updateError: state.applications.updateError,
    updateLoading: state.applications.updateLoading
  }))
  const [loadingXml, setLoadingXml] = useState(false)
  const [markAsFraudulentSuccess, setMarkAsFraudulentSuccess] = useState(false)
  const [showMark, setShowMark] = useState(false)
  const toggle = useCallback(() => setShowMark((prev) => !prev), [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    console.log(`getApplications(${id})`)
    setPageReady(false)
    if (id) dispatch(getApplications(id))
    else setParamError('No application id found')
  }, [props.location])

  useEffect(() => {
    if (!application) return
    setPageReady(true)
  }, [application])

  const markAsFraudulent = async () => {
    await Client.applicationAsFraudulent(application.id).then(() => {
      setMarkAsFraudulentSuccess(true)
    })
    setShowMark(false)
  }

  const showXml = async () => {
    setLoadingXml(true)
    try {
      const response = await Client.getNbkiReport(id)
      let xmlContent = await response.text()
      try {
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(xmlContent, 'application/xml')
        if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
          throw new Error('Invalid XML file')
        }
        const blob = new Blob([xmlContent], {
          type: 'application/xml; charset=UTF-8'
        })
        const url = URL.createObjectURL(blob)
        const newWindow = window.open(url, '_blank')
        newWindow.addEventListener('unload', () => {
          URL.revokeObjectURL(url)
        })
      } catch (error) {
        console.error('Error parsing XML:', error)
      }
    } catch (error) {
      console.error('Error fetching XML:', error)
    } finally {
      setLoadingXml(false)
    }
  }

  const title = t('View application')
  document.title = title
  const bread = <BreadCrumb title={title} />
  let metaBar = <MetaBar backLink={'/applications'} entity={application} />

  if (paramError || listError) {
    return listError ? (
      <ErrorPage backLink={'/applications'} title={title} error={listError} />
    ) : (
      <ErrorPage backLink={'/applications'} title={title} error={paramError} />
    )
  }

  if (!pageReady || listLoading || loadingXml) {
    return <LoadingPage backLink={'/applications'} title={title} />
  }

  const content = application
  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}

            <Button
              disabled={showMark}
              color='danger'
              size='sm'
              style={{ marginTop: '10px', marginBottom: '5px' }}
              onClick={() => {
                setShowMark(true)
              }}
            >
              {t('Mark as fraudulent')}
            </Button>

            <Button
              disabled={false}
              onClick={showXml}
              size='sm'
              style={{
                marginTop: '10px',
                marginBottom: '5px',
                marginLeft: '10px'
              }}
            >
              {t('CheckKI')}
            </Button>
            {showMark && (
              <AskAboutAction
                cancelAction={() => setShowMark(false)}
                sureAction={markAsFraudulent}
                isOpen={showMark}
                toggle={toggle}
                title={t('Mark as fraudulent')}
              />
            )}
            {markAsFraudulentSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')}</strong>
              </Alert>
            )}
            <Row className='mt-3'>
              <EntityInfo
                entity={content}
                entityId={application.id}
                entityType={Client.ENTITY_TYPE.APPLICATIONS}
                columnsDescription={columnsDescription}
              />

              <Code id={id} />
            </Row>
            <Row className='mt-5'>
              <Related application={application} />
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
