import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Client, { ENTITY_TYPE } from 'src/client'
import Tabs from 'src/Components/Common/Tabs'
import { UseQueryHookResultData } from 'src/services/api'
import { useGetLoanQuery } from 'src/services/loans'
import { useGetProfileQuery } from 'src/services/profiles'
import { useSelector } from 'src/store'
import { omit } from 'src/utils'

type Props = {
  loan: UseQueryHookResultData<typeof useGetLoanQuery>
}

export const LoanRelated: FC<Props> = ({ loan }) => {
  const { t } = useTranslation()
  // TODO: move to appropriate entities
  // ex: entityType CLIENTS should have useGetProfileQuery inside it
  const { data: profile } = useGetProfileQuery(loan.profile_id)
  const roles = useSelector((state) => state.Login.userInfo?.roles)

  const items = []

  if (profile && profile.client)
    items.push({
      title: `${t('Client')}`, //`` id: ${loan.profile.client.id}`,
      entity: profile.client,
      entityId: profile.client.id,
      entityType: Client.ENTITY_TYPE.CLIENTS,
      icon: null, //'ri-user-heart-line',
    })

  items.push({
    title: `${t('Profile')}`, // id: ${loan.profile.id}`,
    entityId: loan.profile_id,
    entityType: Client.ENTITY_TYPE.PROFILES,
    icon: null, //'ri-user-settings-line',
  })

  items.push({
    title: t('Communications'),
    entityId: loan.profile_id,
    entityType: ENTITY_TYPE.COMMUNICATIONS,
    icon: null,
  })

  // @ts-expect-error Property 'application' does not exist on type 'Loan'
  if (loan.application) {
    // @ts-expect-error Property 'application' does not exist on type 'Loan'
    const application = omit(loan.application, [
      'profile',
      'profile_id',
      'verification_process',
      'loan',
    ])
    items.push({
      title: `${t('Application')}`, // id: ${loan.application.id}`,
      entity: application,
      // @ts-expect-error Property 'application' does not exist on type 'Loan'
      entityId: loan.application.id,
      entityType: Client.ENTITY_TYPE.APPLICATIONS,
      icon: null, //'ri-pencil-line',
    })
  }

  if (loan.product_copy)
    items.push({
      title: `${t('Product')}`, // id: ${loan.product_copy.id}`,
      loan: loan,
      entityId: loan.product_copy.id,
      entityType: Client.ENTITY_TYPE.PRODUCT,
      icon: null, //'ri-product-hunt-line',
    })

  /*if (loan.product_copy && loan.product_copy.extension_setting) {
    const {created_at, canceled_at, updated_at, ...extension_setting} = loan.product_copy.extension_setting;
    items.push(
      {
        title: t('Extensions'),
        entity: extension_setting,
        entityType: 'extension_setting',
        icon: null, //'ri-coin-line',
      },
    );
  }*/

  items.push({
    title: t('Funds'),
    entityType: 'loan_transactions',
    icon: null, //'ri-coin-line',
    entityId: loan.id,
  })

  items.push({
    title: t('Operations'),
    entityType: 'loan_operations',
    entityId: loan.id,
    icon: null, //'ri-coin-line',
  })

  items.push({
    title: t('Tranches'),
    entityId: loan.id,
    entityType: 'loan_tranches',
    icon: null, //'ri-coin-line',
  })

  items.push({
    title: t('Holidays'),
    entityId: loan.id,
    entityType: 'loan_holidays',
    icon: null, //'ri-coin-line',
  })

  items.push({
    title: t('Agencies'),
    entityId: loan.id,
    entityType: 'loan_agencies',
    icon: null, //'ri-coin-line',
  })

  items.push({
    title: `${t('DocumentsSigned')}`,
    entityId: loan.id,
    entityType: 'documents_signed',
  })

  if (
    roles?.find(
      (role) =>
        role === 'collector' || role === 'operatorBackOffice' || 'admin',
    )
  ) {
    items.push({
      title: `${t('call result')}`,
      entity: loan,
      entityType: 'communications',
    })
  }

  items.push({
    title: t('loans_bki'),
    entityId: loan.id,
    entityType: 'loans_bki',
    icon: null,
  })

  items.push({
    title: t('tags'),
    entityId: loan.id,
    entityType: 'loans_tags',
    icon: null,
  })

  items.push({
    title: t('recurring_payments'),
    entityId: loan.id,
    entityType: 'rec_payments',
    icon: null,
  })

  items.push({
    title: t('Chargeback'),
    entityId: loan.id,
    entityType: 'chargeback',
    icon: null,
  })

  items.push({
    title: t('Atol'),
    entityId: loan.id,
    entityType: 'atol',
    icon: null,
  })

  return <Tabs items={items} />
}
