import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage, { DangerAlert } from '../ErrorPage'

import model from 'src/model'
import {
  useDeleteLoanHolidaysTypeMutation,
  useGetLoanHolidaysTypesQuery
} from 'src/services/holidays'

const headers = {
  id: {},
  created_at: { label: 'Дата создания', isDate: true },
  updated_at: { label: 'Дата обновления', isDate: true },
  name: {},
  is_deleted: {}
}

const actionsEnabled = true
const enableDelete = true

export const HolidaysTypes = () => {
  const { t } = useTranslation()

  const { data: types, error } = useGetLoanHolidaysTypesQuery()

  const [deleteLoanHolidaysType] = useDeleteLoanHolidaysTypeMutation()

  const deleteType = useCallback(
    (id) => {
      deleteLoanHolidaysType(id)
    },
    [deleteLoanHolidaysType]
  )

  const title = 'Типы кредитных каникул'
  document.title = title
  const metaBar = <MetaBar createLink={'/holidays-create'} />

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            {metaBar}
            {error ? <DangerAlert error={error} /> : null}
            <div className='table-responsive table-card mt-4'>
              <Table hover className='table-sm align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th />
                    {Object.keys(headers).map((header) => (
                      <th className='ps-3' key={header} scope='col'>
                        {t(headers[header].label || header)}
                      </th>
                    ))}
                    {actionsEnabled && <th scope='col'>{t('Actions')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {types &&
                    types.length > 0 &&
                    types?.map((entity) => {
                      return (
                        <tr key={entity.id}>
                          <td style={{ width: '1%' }}>
                            <Link
                              to={`/holidays-view?id=${entity.id}`}
                              className='fw-medium'
                            >
                              <i className='ri-eye-line fs-15' />
                            </Link>
                          </td>
                          {Object.keys(headers).map((fieldName) => {
                            if (fieldName === 'id')
                              return (
                                <td className='ps-3' key={fieldName}>
                                  {entity.id}
                                </td>
                              )
                            return (
                              <td className='ps-3' key={fieldName}>
                                {model.fieldValueToText(
                                  fieldName,
                                  entity,
                                  headers
                                )}
                              </td>
                            )
                          })}
                          {actionsEnabled && (
                            <td>
                              <div className='hstack gap-3 flex-wrap'>
                                <Link
                                  to={`/holidays-edit?id=${entity.id}`}
                                  className='link-success fs-15'
                                >
                                  <i className='ri-edit-2-line'></i>
                                </Link>
                                {enableDelete && (
                                  <Link
                                    to='#'
                                    onClick={() => deleteType(entity.id)}
                                    className='link-danger fs-15'
                                  >
                                    <i className='ri-delete-bin-line'></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
