import { api } from './api'

type Chargeback = {
  chargeback_date: string
  created_at: string
  funds_transaction_id: string
  id: string
  updated_at: string | null
  user_id: string
  username: string
  name: string
  surname: string
  midname: string
}

type SendChargeback = {
  transaction_id: string
  chargeback_date: string
}

type Atol = {
  id: number
  created_at: string
  name: string
  is_refunded: boolean
}

const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChargeback: builder.query<Chargeback[], string>({
      query: (loanId) => ({
        url: `crm/v1/payments/chargeback/${loanId}`
      }),
      providesTags: (_result, _err, id) => [{ type: 'Chargeback', id }]
    }),
    setChargeback: builder.mutation<void, SendChargeback>({
      query: (body) => ({
        url: `crm/v1/payments/chargeback`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['Chargeback']
    }),
    getAtolList: builder.query<Atol[], string>({
      query: (loanId) => ({
        url: `crm/v1/loans/${loanId}/atol`
      })
    }),
    setAtolDownload: builder.mutation<
      Blob,
      { loanId: string; atolId: number; refunded: boolean }
    >({
      query: ({ loanId, atolId, refunded }) => ({
        url: `crm/v1/loans/${loanId}/atol/${atolId}?is_refunded=${refunded}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
        formData: true
      })
    }),
    setReloadContract: builder.mutation<
      any,
      {
        loanId: string
        body: {
          drop_pa: boolean
          drop_insurance: boolean
          drop_external_services_ids: string[]
        }
      }
    >({
      query: ({ loanId, body }) => ({
        url: `crm/v1/payments/${loanId}/reload-contract`,
        method: 'POST',
        body: body
      })
    })
  })
})

export const {
  useGetChargebackQuery,
  useSetChargebackMutation,
  useGetAtolListQuery,
  useSetAtolDownloadMutation,
  useSetReloadContractMutation
} = paymentsApi
