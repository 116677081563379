import { api, omitBlankEntries } from './api'

type ApplicationVerification = {
  id: string
  request_id: string
  process: {
    pass: unknown[]
    requirement: unknown[]
    result: unknown[]
  }
}

type ApplicationsTabs = {
  id: string | number
  company: string
  created_at: string
}

const applicationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationStatuses: builder.query<unknown[], string>({
      // who tf came up with this endpoint name?
      query: (id) => `crm/v1/roles/verifications/applications/${id}/decide`
    }),
    getApplicationVerification: builder.query<ApplicationVerification, string>({
      query: (id) => `crm/v1/applications/${id}/verification`
    }),

    getBluredOtp: builder.mutation<any, any>({
      query: ({ request_id }) => ({
        url: `crm/v1/applications/${request_id}/otp`,
        method: 'GET'
      })
    }),

    getApplicationsKI: builder.query<ApplicationsTabs[], string>({
      query: (id) => `/crm/v1/applications/${id}/ki-requests`
    }),
    getApplicationsKIReport: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/applications/ki-report`,
        method: 'GET',
        params: omitBlankEntries(params),
        headers: {
          'Content-Type': 'text/plain; charset=UTF-8'
        },
        responseHandler: (response) => response.text()
      })
    }),
    getApplicationsUPRID: builder.query<ApplicationsTabs[], string>({
      query: (id) => `/crm/v1/applications/${id}/uprid-requests`
    }),
    getApplicationsUPRIDReport: builder.mutation<any, any>({
      query: (params) => ({
        url: `/crm/v1/applications/uprid-result`,
        method: 'GET',
        params: omitBlankEntries(params)
      })
    })
  })
})

export const {
  useGetApplicationStatusesQuery,
  useGetApplicationVerificationQuery,
  useGetBluredOtpMutation,
  useGetApplicationsKIQuery,
  useGetApplicationsKIReportMutation,
  useGetApplicationsUPRIDQuery,
  useGetApplicationsUPRIDReportMutation
} = applicationsApi
