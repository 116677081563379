import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Card, CardBody } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import List from 'src/Components/Common/EntitiesView/List'
import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import model from 'src/model'

import { getTransactions } from 'src/store/actions'

const debounceTimers = {}
const DEBOUNCE_TIMEOUT = 2000

export const Payments = () => {
  const { t } = useTranslation()
  const [fieldsFilter, setFieldsFilter] = useState({})
  const [filter, setFilter] = useState([])
  const [sortOrder, setSortOrder] = useState({
    issue_date: 'desc',
    repayment_date: 'desc',
    amount_debt: 'desc',
    term: 'desc',
    dpd: 'desc'
  })
  const dispatch = useDispatch()
  const { list, listLoading, listError } = useSelector((state) => ({
    list: state.transactions.list,
    listLoading: state.transactions.listLoading,
    listError: state.transactions.listError
  }))

  const sortByValue = (keyName) => {
    const sortedFilter = [...filter]
    const newSortOrder = {
      ...sortOrder,
      [keyName]: sortOrder[keyName] === 'asc' ? 'desc' : 'asc'
    }
    sortedFilter.sort((a, b) => {
      if (newSortOrder[keyName] === 'asc') {
        return a[keyName] - b[keyName]
      } else {
        return b[keyName] - a[keyName]
      }
    })
    setFilter(sortedFilter)
    setSortOrder(newSortOrder)
  }

  const fieldsDescription = {
    id: {
      label: ' ',
      mappingValue: () => <i className='ri-eye-line fs-15' />,
      isLink: true,
      linkFieldName: 'id_link'
    },
    created_at: { isDateTime: true },
    accept_date: { isDateTime: true },
    is_accepted: {
      mappingValue: (value) => (value ? 'Confirmed' : 'Waiting'),
      mappingClassName: (value) => (value ? 'text-success' : 'text-warning')
    },
    direction: {},
    amount: {
      isAmount: true,
      rightAligned: true,
      onClick: () => sortByValue('amount')
    },
    loan_id: {
      label: 'loan',
      mappingValue: () => <i className='ri-pie-chart-line fs-15' />,
      isLink: true,
      linkFieldName: 'loan_link'
    },
    profile_id: {
      label: 'profile',
      mappingValue: () => <i className='ri-account-circle-line fs-15' />,
      isLink: true,
      linkFieldName: 'profile_link'
    },
    last_name: {},
    first_name: {},
    middle_name: {},
    payment_gate: { fieldName: 'comment' }
  }

  useEffect(() => {
    dispatch(getTransactions())
  }, [dispatch])

  useEffect(() => {
    if (list) {
      setFilter(list)
    }
  }, [list])

  useEffect(() => {
    if (Object.keys(fieldsFilter).length === 0) return

    const fieldsFilterJson = JSON.stringify(fieldsFilter)
    clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`])
    debounceTimers[`filtered-entities-${fieldsFilterJson}`] = setTimeout(() => {
      console.log(`request list with ${fieldsFilterJson}`)
      if (fieldsFilter.gender)
        fieldsFilter.gender = model.getGenderCode(fieldsFilter.gender)
      if (fieldsFilter.is_accepted)
        fieldsFilter.is_accepted = model.getPaymentStatus(
          fieldsFilter.is_accepted
        )
      dispatch(getTransactions(null, fieldsFilter))
    }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceTimers[`filtered-entities-${fieldsFilterJson}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`])
      }
    }
  }, [fieldsFilter])

  console.log('transactions =>', list)
  const title = t('Transactions')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  const bread = <BreadCrumb title={t('Transactions')} />
  const metaBar = <MetaBar createLink={'/payment-create'} />

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <FieldsFilter
          fields={{
            direction: { options: ['In', 'Out', 'WriteOff', 'Refund'] },
            is_accepted: { options: ['Confirmed', 'Waiting'] },
            group_delimiter: {},
            created_at: { date: true },
            accept_date: { date: true }
          }}
          applyFilter={(values) => setFieldsFilter(values)}
          resetFilter={() => setFieldsFilter({})}
        />
        <Card>
          <CardBody>
            {metaBar}
            {listLoading ? (
              <Loader />
            ) : (
              <div className='table-responsive table-card mt-3'>
                <List
                  style={{ width: 'max-content' }}
                  list={filter.map((item) => ({
                    ...item,
                    id_link: `/payment-view?id=${item.id}`,
                    profile_link: `/profile-view?id=${item.profile_id}`,
                    loan_link: `/loan-view?id=${item.loan_id}`
                  }))}
                  fieldsDescription={fieldsDescription}
                  className='table-sm align-middle table-nowrap mb-0 mt-3'
                  hover
                />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
