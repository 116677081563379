import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

import { formatAPIDate } from 'src/utils'

import model from 'src/model'

import { getClients, updateClient } from 'src/store/actions'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Select } from './components/select'

const inputInitials = {
  Contact_info: { label: 'Contact_info', isTitle: true },
  last_name: { label: 'last name', initial: '' },
  first_name: { label: 'first name', initial: '' },
  middle_name: { label: 'middle name', initial: '' },
  birth_date: { label: 'birth_date', date: true },
  birth_place: { label: 'birth_place' },
  email: { label: 'email', initial: '' },
  series: { label: 'passport code', initial: '' },
  number: { label: 'passport number', initial: '' },
  docdate: { label: 'passport date', initial: '', date: true },
  docorgcode: { label: 'passport orgcode', initial: '' },
  docorg: { label: 'passport issuer', initial: '' },
  snils: { label: 'snils', initial: '' },
  inn: { label: 'inn', initial: '' },
  thirdparty_fio: { label: 'thirdparty_fio', initial: '' },
  thirdparty_phone: { label: 'thirdparty_phone', initial: '' },

  Busy_info: { label: 'Busy_info', isTitle: true },
  typework: { label: 'typework', initial: '' },
  work_address: { label: 'work_address', initial: '' },
  occupation: { label: 'occupation', initial: '' },
  salary: { label: 'salary', initial: '' },
  extsalary: { label: 'extsalary', initial: '' },
  work_phone: { label: 'work_phone', initial: '' },
  Addresses: { label: 'Addresses', isTitle: true },
}

export const ClientEdit = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [initials, setInitials] = useState(null)
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [paramError, setParamError] = useState(null)
  const [updateSuccess, setUpdateSuccess] = useState(null)
  const [valueError, setValueError] = useState({
    fact_address: false,
    reg_address: false,
    work_address: false,
  })
  const { client, listLoading, listError, updateLoading, updateError } =
    useSelector((state) => ({
      client:
        state.clients.list && state.clients.list.length
          ? state.clients.list[0]
          : null,
      listLoading: state.clients.listLoading,
      listError: state.clients.listError,
      updateError: state.clients.updateError,
      updateLoading: state.clients.updateLoading,
    }))
  const [addresses, setAddresses] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    console.log(`getClients(${id})`)
    setPageReady(false)
    if (id) dispatch(getClients(id))
    else setParamError('No client id found')
  }, [])

  useEffect(() => {
    if (!client) return

    const initialFormValues = {}
    const initials = {}
    Object.keys(inputInitials).forEach((key) => {
      initials[key] = { ...inputInitials[key], initial: client[key] }
    })
    Object.keys(inputInitials).forEach((key) => {
      if (key === 'gender') {
        initialFormValues[key] = model.getStrGender(client[key])
        return
      }
      initialFormValues[key] = client[key]
    })

    setInitials(initials)
    setInitialFormValues(initialFormValues)

    setAddresses({
      fact_address: {
        data: client.addresses.fact_address,
        value: client.addresses_label.fact_address,
      },
      reg_address: {
        data: client.addresses.reg_address,
        value: client.addresses_label.reg_address,
      },
      work_address: {
        data: client.addresses.work_address,
        value: client.addresses_label.work_address,
      },
    })

    setPageReady(true)
  }, [client])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({
      series: Yup.number(),
      number: Yup.number(),
      snils: Yup.number().nullable(),
      inn: Yup.number().nullable(),
      salary: Yup.number(),
      extsalary: Yup.number().nullable(),
      phonework: Yup.number(),
      thirdparty_phone: Yup.number().nullable(),
      thirdparty_fio: Yup.string().nullable(),
      docdate: Yup.date(),
      docorgcode: Yup.string().matches(
        /^[0-9][0-9][0-9]-[0-9][0-9][0-9]+$/,
        'Format must be XXX-XXX',
      ),
    }),
    onSubmit: (values) => {
      const updatedClient = { id: client.id, ...values }
      updatedClient.gender = model.getGenderCode(updatedClient.gender)
      const workData = {
        client_id: client.id,
        typework: updatedClient.typework,
        placework: updatedClient.work_address,
        occupation: updatedClient.occupation,
        salary: Number(updatedClient.salary),
        phonework: updatedClient.work_phone,
      }

      if (updatedClient.extsalary) {
        workData.extsalary = Number(updatedClient.extsalary)
      }

      const documentsData = {
        client_id: client.id,
        snils: updatedClient.snils,
        inn: updatedClient.inn,
      }

      const addressData = {
        client_id: client.id,
        addresses: {
          fact_address: addresses.fact_address.data,
          reg_address: addresses.reg_address.data,
          work_address: addresses.work_address.data,
        },
        addresses_label: {
          fact_address: addresses.fact_address.value,
          reg_address: addresses.reg_address.value,
          work_address: addresses.work_address.value,
        },
      }

      let form = document.getElementById('formElem')
      const formData = new FormData(form)
      formData.set('client_id', updatedClient.id)
      formData.set('name', updatedClient.first_name)
      formData.set('surname', updatedClient.last_name)
      formData.set('midname', updatedClient.middle_name)
      formData.set('docdate', `${updatedClient.docdate}`)
      formData.set('birthdate', `${updatedClient.birth_date}`)
      formData.set('birthplace', `${updatedClient.birth_place}`)
      formData.delete('middle_name')
      formData.delete('birth_date')
      formData.delete('birth_place')
      formData.delete('first_name')
      formData.delete('last_name')
      formData.delete('last_name')
      formData.delete('snils')
      formData.delete('typework')
      formData.delete('work_address')
      formData.delete('occupation')
      formData.delete('salary')
      formData.delete('extsalary')
      formData.delete('work_phone')
      if (
        !valueError.fact_address &&
        !valueError.reg_address &&
        !valueError.work_address
      ) {
        dispatch(
          updateClient({ formData, addressData, documentsData, workData }),
        )
        setUpdateSuccess(true)
      }
    },
  })

  console.log('client=>', client)
  document.title = t('Edit client')
  const bread = <BreadCrumb title={`${t('Edit client')}`} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>

  if (paramError || listError) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          <Container fluid={true}>
            {bread}
            <Alert color="warning">
              <strong> {!!paramError && paramError.toString()} </strong>
              <strong> {!!listError && listError.toString()} </strong>
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  if (!pageReady || listLoading) {
    return (
      <React.Fragment>
        <UiContent />
        <div className="page-content">
          <Container fluid={true}>
            {bread}
            {loading}
          </Container>
        </div>
      </React.Fragment>
    )
  }

  let metaBar = (
    <MetaBar backLink={`/client-view?id=${client.id}`} entity={client} />
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Container fluid={true} className="mt-4">
              <Row>
                <Col lg={12}>
                  <Form
                    id="formElem"
                    className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      {Object.keys(initials).map((fieldKey) => {
                        const htmlForId = `validation-${fieldKey}`
                        if (initials[fieldKey].isTitle) {
                          return (
                            <div
                              key={fieldKey}
                              className={'bg-soft-primary'}
                              style={{
                                height: '30px',
                                lineHeight: '30px',
                                marginBottom: '5px',
                              }}
                            >
                              <strong>{t(initials[fieldKey].label)}</strong>
                            </div>
                          )
                        }
                        return (
                          <Col md="4" key={`${fieldKey}`}>
                            <FormGroup className="mb-3">
                              <Label htmlFor={htmlForId}>
                                {t(initials[fieldKey].label)}
                              </Label>
                              {initials[fieldKey].options &&
                              initials[fieldKey].options.length ? (
                                <select
                                  name={`${fieldKey}`}
                                  id={htmlForId}
                                  className="form-select form-select-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[fieldKey] || ''}
                                >
                                  {initials[fieldKey].options.map((option) => (
                                    <option
                                      key={option.name}
                                      defaultValue={option.defaultValue}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              ) : initials[fieldKey].date ? (
                                <Flatpickr
                                  name={`${fieldKey}`}
                                  id={htmlForId}
                                  value={validation.values[fieldKey] || ''}
                                  className="form-control form-control-sm"
                                  options={{
                                    altInput: true,
                                    allowInput: true,
                                    dateFormat: 'Y-m-d',
                                    altFormat: 'd.m.Y',
                                    onChange: (selectedDates, dateStr) => {
                                      console.log(
                                        'selectedDate, dateStr',
                                        selectedDates,
                                        dateStr,
                                      )
                                      validation.setFieldValue(
                                        fieldKey,
                                        formatAPIDate(selectedDates[0]),
                                      )
                                    },
                                  }}
                                />
                              ) : (
                                <Input
                                  name={`${fieldKey}`}
                                  placeholder={initials[fieldKey].placeholder}
                                  type="text"
                                  className="form-control form-control-sm"
                                  id={htmlForId}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[fieldKey] || ''}
                                  invalid={
                                    !!(
                                      validation.touched[fieldKey] &&
                                      validation.errors[fieldKey]
                                    )
                                  }
                                />
                              )}
                              {validation.touched[fieldKey] &&
                              validation.errors[fieldKey] ? (
                                <FormFeedback type="invalid">
                                  {validation.errors[fieldKey]}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        )
                      })}
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Select
                            onError={(cond) =>
                              setValueError({
                                ...valueError,
                                fact_address: cond,
                              })
                            }
                            value={addresses.fact_address.value}
                            label={t('fact_address')}
                            onChange={(item) =>
                              setAddresses({ ...addresses, fact_address: item })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Select
                            onError={(cond) =>
                              setValueError({
                                ...valueError,
                                reg_address: cond,
                              })
                            }
                            value={addresses.reg_address.value}
                            label={t('reg_address')}
                            onChange={(item) =>
                              setAddresses({ ...addresses, reg_address: item })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Select
                            onError={(cond) =>
                              setValueError({
                                ...valueError,
                                work_address: cond,
                              })
                            }
                            value={addresses.work_address.value}
                            label={t('workAddress')}
                            onChange={(item) =>
                              setAddresses({ ...addresses, work_address: item })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {!!updateError && (
                      <Alert color="warning">
                        <strong> {updateError.toString()} </strong>
                      </Alert>
                    )}

                    {!updateError && updateSuccess && (
                      <Alert color="success">
                        <strong> {t('Operation success')} </strong>
                      </Alert>
                    )}

                    {updateLoading ? (
                      loading
                    ) : (
                      <Button color="success" type="submit">
                        {t('Update')}
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
