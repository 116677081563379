import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'
import { useGetPromocodeQuery } from 'src/services/promocodes'
import { useSearchParams } from 'react-router-dom'

const fieldsDescription = {
  id: {},
  sale: { fullWidth: true, isAmount: true },
  promocode: { fullWidth: true },
  begin_date: { label: 'promocode_start_date', isDate: true },
  end_date: { label: 'promocode_end_date', isDate: true },
  limitless: {
    label: 'type',
    mappingValue: (value) => (value ? 'Многоразовый' : 'Ограниченный'),
  },
  available_usages: { label: 'Available usages' },
  total_usage_limit: { label: 'Total usage limit' },
}

export const PromocodeView = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)

  const {
    data: entity,
    isLoading: entityLoading,
    error: entityError,
  } = useGetPromocodeQuery(searchParams.get('id'), {
    skip: searchParams.get('id') == null,
  })

  useEffect(() => {
    if (!searchParams.get('id')) setParamError('No entity id found')
  }, [searchParams])

  useEffect(() => {
    setPageReady(!!entity)
  }, [entity])

  const title = `${t('Promocodes')}: ${t('View')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/promocodes'}
      editLink={entity ? `/promocodes-edit?id=${entity.id}` : null}
      entity={entity}
    />
  )

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/promocodes'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/promocodes'} title={title} />
  }

  console.log('entity =>', entity)
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col
                    className={
                      fieldsDescription[fieldName].fullWidth
                        ? 'col-12 mt-3'
                        : 'mt-3 col-auto'
                    }
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>
                      {t(fieldsDescription[fieldName].label || fieldName)}
                    </Label>
                    <Input
                      type="text"
                      className="form-control form-control-sm"
                      value={model.fieldValueToText(
                        fieldName,
                        entity,
                        fieldsDescription,
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
