import { FC, ReactNode } from 'react'
import { Alert, Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'

type ErrorPageProps = {
  backLink?: string | undefined
  title: string
  // error?:
  //   | { data: { detail: unknown } }
  //   | { status: string }
  //   | { detail: unknown }
  //   | string
  //   | & {}
  error?: any
}

const ErrorPage: FC<ErrorPageProps> = ({ backLink, title, error }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='page-content'>
        <BreadCrumb title={t(title)} />
        <Card>
          <CardBody>
            <MetaBar backLink={backLink ? backLink : null} />
            <DangerAlert error={error} />
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export const DangerAlert = ({
  error,
  mapError
}: {
  error: ErrorPageProps['error']
  mapError?: boolean | ((error: ErrorPageProps['error']) => ReactNode)
}) => {
  const { t } = useTranslation()

  // if mapError is `null` or `undefined` but not `false`
  // q: why not pass this function directly to props destructuring?
  // a: because of the `t` function from `useTranslation` hook
  // eslint-disable-next-line react/display-name
  mapError ??= (error) => {
    if (typeof error === 'object' && 'status' in error)
      switch (error.status) {
        case 403:
          return t('Insufficient priviliges')

        default:
          return t('Unknown error')
      }

    // src/client/`apiQuery` already maps error status to string
    switch (error) {
      case 'Access error':
        return t('Insufficient priviliges')

      default:
        return <span>{JSON.stringify(error)}</span>
    }
  }

  return (
    <Alert color='danger' className='alert-top-border'>
      <i className='ri-airplay-line me-3 align-middle fs-16 text-danger' />
      <strong>{`${t('smth_wrong')}: `}</strong>
      {typeof mapError === 'function'
        ? mapError(error)
        : !!error && error.data
        ? `${error.status} ${JSON.stringify(error.data.detail)}`
        : error.toString()}
    </Alert>
  )
}

export default ErrorPage
