import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Table, Alert } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import LimitNotice from 'src/Components/Common/LimitNotice'
import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'

import model from 'src/model'

import { useTranslation } from 'react-i18next'

import { getClients, deleteClient } from 'src/store/actions'

import { formatUIDate, formatAmount, formatCalculateAge } from 'src/utils'

const enableDelete = false
const enableEdit = false

const headers = {
  id: { label: '' },
  last_name: { label: 'last_name' },
  first_name: { label: 'first_name' },
  middle_name: { label: 'middle_name' },
  gender: { label: 'gender' },
  birth_date: { label: 'birth_date' },
  age: { label: 'age' },
  position: { label: 'position' },
  salary: { label: 'salary' }
}

const debounceClientTimers = {}
const DEBOUNCE_TIMEOUT = 1000

export const Clients = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [strFilter, setStrFilter] = useState('')
  const [fieldsFilter, setFieldsFilter] = useState({})

  const { list, listLoading, listError } = useSelector((state) => ({
    list: state.clients.list,
    listLoading: state.clients.listLoading,
    listError: state.clients.listError
  }))

  useEffect(() => {
    dispatch(getClients())
  }, [dispatch])

  useEffect(() => {
    if (!strFilter) return

    clearTimeout(debounceClientTimers[`filtered-clients-${strFilter}`])
    debounceClientTimers[`filtered-clients-${strFilter}`] = setTimeout(() => {
      console.log(`request clients with ${strFilter}`)
      dispatch(getClients(null, strFilter))
    }, DEBOUNCE_TIMEOUT)

    return () => {
      if (debounceClientTimers[`filtered-clients-${strFilter}`]) {
        clearTimeout(debounceClientTimers[`filtered-clients-${strFilter}`])
      }
    }
  }, [strFilter])

  useEffect(() => {
    if (Object.keys(fieldsFilter).length === 0) return

    const fieldsFilterJson = JSON.stringify(fieldsFilter)
    clearTimeout(debounceClientTimers[`filtered-entities-${fieldsFilterJson}`])
    debounceClientTimers[`filtered-entities-${fieldsFilterJson}`] = setTimeout(
      () => {
        console.log(`request list with ${fieldsFilterJson}`)
        if (fieldsFilter.gender)
          fieldsFilter.gender = model.getGenderCode(fieldsFilter.gender)
        dispatch(getClients(null, fieldsFilter))
      },
      DEBOUNCE_TIMEOUT
    )

    return () => {
      if (debounceClientTimers[`filtered-entities-${fieldsFilterJson}`]) {
        clearTimeout(
          debounceClientTimers[`filtered-entities-${fieldsFilterJson}`]
        )
      }
    }
  }, [fieldsFilter])

  const archive = (id) => {
    dispatch(deleteClient(id))
  }

  console.log('clients =>', list)
  const title = t('Clients')
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  const bread = <BreadCrumb title={t('Clients')} />
  const minimizeTextStyle = {
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden'
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card
          style={
            {
              /*maxWidth: 900*/
            }
          }
        >
          <CardBody>
            <div className='live-preview'>
              <div className='table-responsive table-card'>
                <div className='gridjs-head'>
                  <div className='gridjs-search'>
                    <input
                      type='search'
                      className='gridjs-input gridjs-search-input'
                      id='iconInput'
                      placeholder='Search...'
                      onChange={(event) => {
                        const filter = event.target.value
                        setStrFilter(filter)
                      }}
                    />
                  </div>
                </div>

                <FieldsFilter
                  fields={{
                    client_id: {},
                    profile_id: {},
                    phone: {},
                    email: {},
                    group_delimiter: {},
                    last_name: {},
                    first_name: {},
                    middle_name: {},
                    group_delimiter_2: {},
                    birth_date: { date: true },
                    gender: { options: ['male', 'female'] }
                  }}
                  applyFilter={(values) => setFieldsFilter(values)}
                  resetFilter={() => setFieldsFilter({})}
                />

                {!!listError && (
                  <Alert color='warning'>
                    <strong> {listError.toString()} </strong>
                  </Alert>
                )}

                <LimitNotice limit={model.LIST_LIMIT} />
                {listLoading ? (
                  <Loader />
                ) : (
                  <Table
                    hover
                    className='table-sm align-middle table-nowrap mb-0'
                    style={{ width: 'max-content' }}
                  >
                    <thead>
                      <tr>
                        {Object.keys(headers).map((header) => (
                          <th
                            className='ps-3'
                            key={header}
                            scope='col'
                            style={
                              header === 'salary' ? { textAlign: 'end' } : null
                            }
                          >
                            {t(headers[header].label)}
                          </th>
                        ))}
                        {enableEdit ||
                          (enableDelete && <th scope='col'>{t('Actions')}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.length > 0 &&
                        list.map((client) => {
                          return (
                            <tr key={client.id}>
                              {Object.keys(headers).map((productFieldName) => {
                                const value = client[productFieldName]
                                const key = `${productFieldName}`
                                if (productFieldName === 'id')
                                  return (
                                    <th
                                      scope='row'
                                      key={key}
                                      style={{ width: '1%' }}
                                    >
                                      <Link
                                        to={`/client-view?id=${value}`}
                                        className='fw-medium'
                                      >
                                        <i className='ri-eye-line fs-15' />
                                      </Link>
                                    </th>
                                  )
                                if (productFieldName === 'birth_date')
                                  return (
                                    <td
                                      className='ps-3'
                                      style={minimizeTextStyle}
                                      key={key}
                                    >
                                      {formatUIDate(client.birth_date)}
                                    </td>
                                  )
                                if (productFieldName === 'age')
                                  return (
                                    <td
                                      className='ps-3'
                                      style={minimizeTextStyle}
                                      key={key}
                                      align='center'
                                    >
                                      {formatCalculateAge(client.birth_date)}
                                    </td>
                                  )
                                if (productFieldName === 'gender')
                                  return (
                                    <td
                                      className='ps-3'
                                      style={minimizeTextStyle}
                                      key={key}
                                    >
                                      {t(model.getStrGender(client.gender))}
                                    </td>
                                  )
                                if (productFieldName === 'salary')
                                  return (
                                    <td
                                      className='ps-3'
                                      style={minimizeTextStyle}
                                      key={key}
                                      align='right'
                                    >
                                      {formatAmount(client.salary)}
                                    </td>
                                  )

                                return (
                                  <td
                                    className='ps-3'
                                    style={minimizeTextStyle}
                                    key={key}
                                  >
                                    {value}
                                  </td>
                                )
                              })}
                              {enableEdit ||
                                (enableDelete && (
                                  <td>
                                    <div className='hstack gap-3 flex-wrap'>
                                      {enableEdit && (
                                        <Link
                                          to={`/client-edit?id=${client.id}`}
                                          className='link-success fs-15'
                                        >
                                          <i className='ri-edit-2-line'></i>
                                        </Link>
                                      )}
                                      {enableDelete && (
                                        <Link
                                          to='#'
                                          onClick={() => archive(client.id)}
                                          className='link-danger fs-15'
                                        >
                                          <i className='ri-delete-bin-line'></i>
                                        </Link>
                                      )}
                                    </div>
                                  </td>
                                ))}
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
