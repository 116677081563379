import { useState, useEffect } from 'react'
import Table4Columns from './Table4Columns'

import { useTranslation } from 'react-i18next'

import { Row, Col, Modal, Button } from 'reactstrap'

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import { Pagination, Navigation } from 'swiper'

import model from 'src/model'
import { formatCalculateAge, formatDateWithDots } from 'src/utils'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const columnsDescriptionDefault = [
  ['title_contact_info'],
  ['id'],
  // ['profile_id'],
  //  ['last_name'],
  //  ['first_name'],
  //  ['middle_name'],
  ['FIO'],
  // ['id_type'],
  // ['id_number'], //'id_series', 'id_issuer', 'id_code', 'id_date',
  ['gender'],
  ['age'],
  // ['birth_date'],
  ['birth_place'],
  ['series'],
  ['number'],
  ['docdate'],
  ['docorgcode'],
  ['docorg'],
  ['snils'],
  ['inn'],
  // ['birth_date'],
  // ['marital_status'],
  // ['number_of_children'],
  ['contact_person_name_1'],
  ['contact_person_phone_1'],
  // ['contact_person_2'],

  ['title_busy_info'],
  // ['university'],
  // ['field_of_activity'],
  ['typework'],
  ['work_address'],
  ['salary'],
  ['extsalary'],
  // ['describe_work'],
  // ['level_of_education'],
  ['occupation'],
  ['work_phone'],

  // ['title_payment'],
  // ["select_payment"],
  // ["bank_name"],
  // ["account_number"],
  // ["electronic_wallet"],
  // ["electronic_wallet_phone"],

  ['title_residence'],
  // ['time_of_residence'],
  // ['fact_address_match'],

  // ['title_current_address', 'title_fact_address'],

  ['current_address'],
  ['fact_address'],
  ['workAddress'],
  // ['current_address_city', 'fact_address_city'],
  //  ['current_address_city_not_exist', 'fact_address_city_not_exist'],
  //  ['current_address_enter_city', 'fact_address_enter_city'],
  //  ['current_address_barangay', 'fact_address_barangay'],
  //  ['current_address_district', 'fact_address_district'],
  //   ['current_address_street', 'fact_address_street'],
  //   ['current_address_house', 'fact_address_house'],
  //   ['current_address_apt', 'fact_address_apt'],

  // ['title_other'],
  // ['loan_purpose'],
  // ['enter_purpose'],
  // ['direct_conditions'],
]

const fieldsDescription = {
  id: { isLink: true, linkFieldName: 'client_link' },
  profile_id: { isLink: true, linkFieldName: 'profile_link' },
  title_contact_info: { isTitle: true, text: 'Contact_info' },
  title_busy_info: { isTitle: true, text: 'Busy_info' },
  title_payment: { isTitle: true, text: 'Payment_info' },
  title_residence: { isTitle: true, text: 'Residence_info' },

  title_current_address: {
    isTitle: true,
    titleLevel: 2,
    text: 'current_address',
  },
  title_fact_address: { isTitle: true, titleLevel: 2, text: 'fact_address' },

  title_other: { isTitle: true, text: 'Other' },

  empty: { isEmpty: true },
  salary: { isAmount: true },
  extsalary: { isAmount: true },
  gender: { isGender: true },
  birth_date: { isDate: true },
  docdate: { isDate: true },
  id_date: { isDate: true },
  // age: { isDate: true},
  // fio: {}

  // contact_person_1: {isContact: true, nameField: 'contact_person_name_1', phoneField: 'contact_person_phone_1' },
  // contact_person_2: {isContact: true, nameField: 'contact_person_name_2', phoneField: 'contact_person_phone_2' },
  // new_line: {isEmpty: true, newLine: true},
}

const ModalDocument = ({ isOpen, onClose, doc }) => {
  const { t } = useTranslation()
  if (!doc) return null
  return (
    <Modal isOpen={isOpen} centered>
      <div className="modal-body p-4 pb-2">
        <div className="vstack d-flex align-items-center justify-content-center p-2">
          <img
            src={model.getDocumentImgUrl(doc)}
            alt=""
            className="rounded"
            width="400"
          />
          <div
            className="vstack mt-2 mb-0 align-self-center"
            style={{ width: 'fit-content' }}
          >
            {doc.file_type_id && (
              <small>{`${t('file_type_id')}: ${model.fieldValueToText(
                'file_type_id',
                doc,
                {},
              )}`}</small>
            )}
            {doc.file_type?.name && (
              <small>{`${model.fieldValueToText(
                'name',
                doc.file_type,
                {},
              )}`}</small>
            )}
            {doc.created_at && (
              <small>{`${t('created')}: ${model.fieldValueToText(
                'created_at',
                doc,
                {
                  created_at: { isDateTime: true },
                },
              )}`}</small>
            )}
            {Object.keys(doc).includes('is_incorrect') && (
              <small>{`${t('is_incorrect')}: ${model.fieldValueToText(
                'is_incorrect',
                doc,
                {},
              )}`}</small>
            )}
            <small>{`${t('id')}: ${model.fieldValueToText(
              'id',
              doc,
              {},
            )}`}</small>
          </div>
          <Button className="mt-3" color="light" onClick={() => onClose()}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const Photos = (props) => {
  const { photos } = props
  const { t } = useTranslation()

  const [modal_center, setmodal_center] = useState(false)
  const [docActive, setDocActive] = useState(null)
  function tog_center(img) {
    setDocActive(img)
    setmodal_center(!modal_center)
  }

  if (!photos || !photos.length) {
    return <small>{t('No_photos')}</small>
  }

  return (
    <div
      style={props.style || { maxWidth: 400 }}
      className={props.className || 'mt-4 mb-5'}
    >
      <Swiper
        pagination={{ type: 'fraction', clickable: true }}
        navigation={true}
        modules={[Pagination, Navigation]}
        loop={true}
        className="mySwiper swiper default-swiper rounded bg-soft-primary"
      >
        <div className="swiper-wrapper align-items-center">
          {photos.map((doc) => {
            const imgSource = model.getDocumentImgUrl(doc)
            //console.log('imgSource', imgSource);
            return (
              <SwiperSlide key={doc.id}>
                <div
                  className="vstack d-flex align-items-center justify-content-center p-2"
                  onClick={() => tog_center(doc)}
                >
                  <img
                    style={{ height: props.decisionView ? 100 : undefined }}
                    src={imgSource}
                    alt=""
                    className="img-thumbnail rounded"
                  />
                  <div
                    className="vstack mb-5 align-self-center"
                    style={{ width: 'fit-content' }}
                  >
                    {doc.file_type_id && (
                      <small>{`${t('file_type_id')}: ${model.fieldValueToText(
                        'file_type_id',
                        doc,
                        {},
                      )}`}</small>
                    )}
                    {doc.file_type?.name && (
                      <small>{`${model.fieldValueToText(
                        'name',
                        doc.file_type,
                        {},
                      )}`}</small>
                    )}
                    {doc.created_at && (
                      <small>{`${t('created')}: ${model.fieldValueToText(
                        'created_at',
                        doc,
                        {
                          created_at: { isDateTime: true },
                        },
                      )}`}</small>
                    )}
                    {Object.keys(doc).includes('is_incorrect') && (
                      <small>{`${t('is_incorrect')}: ${model.fieldValueToText(
                        'is_incorrect',
                        doc,
                        {},
                      )}`}</small>
                    )}
                    <small>{`${t('id')}: ${model.fieldValueToText(
                      'id',
                      doc,
                      {},
                    )}`}</small>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </div>
      </Swiper>
      <ModalDocument
        isOpen={modal_center}
        centered
        onClose={() => setmodal_center(false)}
        doc={docActive}
      />
    </div>
  )
}

const EntityContent = ({ entity, columnsDescription, error }) => {
  useEffect(() => {
    console.log('client =>', entity)
  }, [entity])

  const docsEnabled = entity.documents && entity.documents.length > 0

  const current_address = entity?.addresses_label?.reg_address

  const fact_address = entity?.addresses_label?.fact_address

  // const { city, street, house, flat } = entity.addresses.work_address || {};

  const workAddress =
    entity?.addresses_label?.work_address !== null
      ? entity?.addresses_label?.work_address
      : ' '

  const formattedBDay = formatDateWithDots(entity?.birth_date)
  // const formattedDocDay = formatDateWithDash(entity?.docdate)
  const FIO = `${entity?.last_name} ${entity?.first_name} ${entity?.middle_name} ${formattedBDay}`
  const age = `${formatCalculateAge(entity?.birth_date)}`

  return (
    <div style={{}}>
      <Row>
        {error && <DangerAlert error={error} />}
        <Col xl="12" xxl="8" className="order-2 order-xxl-1">
          <Table4Columns
            columnsDescription={columnsDescription || columnsDescriptionDefault}
            fieldsDescription={fieldsDescription}
            className="table-sm align-middle mb-0"
            style={{ width: 'max-content' }}
            entity={{
              ...entity,
              current_address,
              fact_address,
              workAddress,
              FIO,
              age,
              client_link: `/client-view?id=${entity.id}`,
              profile_link: `/profile-view?id=${entity.profile_id}`,
            }}
          />
        </Col>
        {docsEnabled && (
          <Col
            xxl="4"
            xl="12"
            className="order-1 order-xxl-2 d-flex align-items-start justify-content-start"
          >
            <Photos photos={entity.documents} />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default EntityContent
