import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { getTransactions } from 'src/store/actions'

import model from 'src/model'

import { Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap'

const enableEdit = false

const inputInitials = {
  loan_id: { label: 'id', initial: '', placeholder: '-' },
  direction: { label: 'direction', initial: '', placeholder: '-' },
  amount: { isAmount: true, label: 'amount', initial: '', placeholder: '-' },
  comment: { label: 'comment', initial: '', placeholder: '-' },
  is_accepted: { label: 'is_accepted', initial: '-', placeholder: '-' },
  accept_date: {
    isDateTime: true,
    label: 'accept_date',
    initial: '',
    placeholder: '-',
  },
  last_name: { label: 'last_name', initial: '', placeholder: '-' },
  first_name: { label: 'first_name', initial: '', placeholder: '-' },
  middle_name: { label: 'middle_name', initial: '', placeholder: '-' },
}

export const PaymentView = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const { payment, listLoading, listError } = useSelector((state) => ({
    payment:
      state.transactions.list && state.transactions.list.length
        ? state.transactions.list[0]
        : null,
    listLoading: state.transactions.listLoading,
    listError: state.transactions.listError,
    updateError: state.transactions.updateError,
    updateLoading: state.transactions.updateLoading,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    const edit = params.get('edit')
    setId(id || null)
    setIsEdit(edit === 'true' && enableEdit)
    console.log(`getTransactions(${id})`)
    setPageReady(false)
    if (id) dispatch(getTransactions(id))
    else setParamError('No payment id found')
  }, [props.location])

  useEffect(() => {
    setPageReady(!!payment)
  }, [payment])

  console.log('payment=>', payment)
  const pageTitle = t(isEdit ? 'Edit payment' : 'View payment')
  document.title = t(pageTitle)
  const bread = <BreadCrumb title={pageTitle} />
  const profileLink =
    payment && payment.profile_id ? (
      <Link to={`/profile-view?id=${payment.profile_id}`}>
        <Button className="btn-icon" style={{ backgroundColor: '#405189' }}>
          <i className="ri-account-circle-line fs-16"></i>
        </Button>
      </Link>
    ) : null

  const loanLink =
    payment && payment.loan_id ? (
      <Link to={`/loan-view?id=${payment.loan_id}`}>
        <Button className="btn-icon" style={{ backgroundColor: '#405189' }}>
          <i className="ri-pie-chart-line fs-16"></i>
        </Button>
      </Link>
    ) : null

  if (!payment && (paramError || listError)) {
    return listError ? (
      <ErrorPage backLink={'/payments'} title={pageTitle} error={listError} />
    ) : (
      <ErrorPage backLink={'/payments'} title={pageTitle} error={paramError} />
    )
  }

  if (!payment && (!pageReady || listLoading)) {
    return <LoadingPage backLink={'/payments'} title={pageTitle} />
  }

  const metaBar = (
    <MetaBar
      backLink={'/payments'}
      editLink={
        !isEdit && enableEdit
          ? `/payment-view?id=${payment.id}&edit=true`
          : null
      }
      entity={payment}
      buttons={[profileLink, loanLink]}
    />
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              {Object.keys(inputInitials).map((fieldKey, i) => {
                const htmlForId = `validation-${fieldKey}`
                return (
                  <Col className="col-auto" key={`${fieldKey}`}>
                    <Label htmlFor={htmlForId}>
                      {t(inputInitials[fieldKey].label)}
                    </Label>
                    <Input
                      name={`${fieldKey}`}
                      type="text"
                      className="form-control form-control-sm"
                      id={htmlForId}
                      value={model.fieldValueToText(
                        fieldKey,
                        payment,
                        inputInitials,
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
