import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { getExternalServicesSettings } from 'src/store/actions'

import model from 'src/model'

import { Row, Col, Card, CardBody, Input, Label } from 'reactstrap'

const fieldsDescription = {
  enabled: {},
  name: {},
  amount: { isAmount: true },
  subtract_when_issuing: {},
}

export const ExternalServicesSettingsView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const { entity, listError, listLoading } = useSelector((state) => ({
    entity:
      state.externalServicesSettings.list &&
      state.externalServicesSettings.list.length
        ? state.externalServicesSettings.list[0]
        : null,
    listError: state.externalServicesSettings.listError,
    listLoading: state.externalServicesSettings.listLoading,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    if (id) dispatch(getExternalServicesSettings(id))
    else setParamError('No entity id found')
  }, [])

  useEffect(() => {
    if (!entity) return
    setPageReady(true)
  }, [entity])

  const title = t('View_external_services_settings')
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/external-services-settings'}
      editLink={
        entity ? `/external-services-settings-edit?id=${entity.id}` : null
      }
      entity={entity}
    />
  )

  if (paramError || listError) {
    return (
      <ErrorPage
        backLink={'/external-services-settings'}
        title={title}
        error={[paramError, listError]}
      />
    )
  }

  if (!pageReady || listLoading) {
    return (
      <LoadingPage backLink={'/external-services-settings'} title={title} />
    )
  }

  console.log('entity =>', entity)
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col className="col-auto" key={`${fieldName}`}>
                    <Label htmlFor={fieldName}>{t(fieldName)}</Label>
                    <Input
                      type="text"
                      className="form-control form-control-sm"
                      value={model.fieldValueToText(
                        fieldName,
                        entity,
                        fieldsDescription,
                      )}
                      disabled
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
