import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import Select from 'react-select'
import Client from 'src/client'

import { getEntities, updateEntities, createEntities } from 'src/store/actions'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

const boolean_options = [
  { name: 'внешняя', value: true },
  { name: 'внутренняя', value: false }
]

const inputInitials = {
  full_name: {
    label: 'full_name',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  short_name: {
    label: 'short_name',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  inn: { label: 'inn', initial: '', placeholder: '', fullWidth: true },
  kpp: { label: 'kpp', initial: '', placeholder: '', fullWidth: true },
  ogrn: { label: 'ogrn', initial: '', placeholder: '', fullWidth: true },

  communication_phone: {
    label: 'communication_phone',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  customer_phone: {
    label: 'customer_phone',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  communication_email: {
    label: 'communication_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  customer_support_email: {
    label: 'customer_support_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  registry_email: {
    label: 'registry_email',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  postal_address: {
    label: 'postal_address',
    initial: '',
    placeholder: '',
    fullWidth: true
  },
  legal_address: {
    label: 'legal_address',
    initial: '',
    placeholder: '',
    fullWidth: true
  },

  is_external: {
    label: 'is_external',
    initial: true,
    fullWidth: true,
    options: boolean_options
  }
}

export const AgencyEdit = () => {
  const { t } = useTranslation()
  const params = new URLSearchParams(window.location.search)
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [entity, setEntity] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [show, setShow] = useState(false)
  const [options, setOptions] = useState([])
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState([])
  const [paramError] = useState(null)
  const {
    entityLoaded,
    entityLoading,
    entityError,
    updateLoading,
    updateError,
    createError,
    createLoading
  } = useSelector((state) => ({
    entityLoaded:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].entity,
    entityLoading:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].entityLoading,
    entityError:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].entityError,
    updateError:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].updateError,
    createError:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].createError,
    updateLoading:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].updateLoading,
    createLoading:
      state.entities[Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL].createLoading
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    console.log('id => ', id)
    setId(id || null)
    if (id) {
      //edit mode
      dispatch(getEntities(Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL, id))
    } else {
      //create mode

      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        initialFormValues[key] = inputInitials[key].initial
      })
      setInitialFormValues(initialFormValues)
      setPageReady(true)
    }
  }, [])

  useEffect(() => {
    Client.getAllKASMSTemplates()
      .then((res) => {
        let arr = []
        for (let i = 0; i < res.length - 1; i++) {
          arr.push({
            value: res[i].id,
            label: res[i].template
          })
        }
        setOptions(arr)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (id && entityLoading === false) {
      //edit mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        if (inputInitials[key].label === 'is_external') {
          initialFormValues[key] =
            entityLoaded[key] !== null ? entityLoaded[key] : false
        } else
          initialFormValues[key] =
            entityLoaded &&
            entityLoaded[key] !== null &&
            entityLoaded[key] !== undefined
              ? entityLoaded[key].toString()
              : ''
      })
      console.log('edit mode, init fields =>', initialFormValues)
      setInitialFormValues(initialFormValues)
      setEntity(entityLoaded)
      setPageReady(true)
    }
  }, [entityLoading])

  const linkKAToAgencies = () => {
    Client.linkKA(id, selectedTemplate).then((res) => console.log(res))
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const { ...data } = values

      if (values.is_external === true || values.is_external === 'true') {
        data.is_external = true
      } else {
        data.is_external = null
      }

      if (id) {
        dispatch(
          updateEntities(
            { id, ...data },
            Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL
          )
        )
        linkKAToAgencies()
      } else
        dispatch(createEntities(data, Client.ENTITY_TYPE.COLLECTIONS_EXTERNAL))
    }
  })

  useEffect(() => {
    validation.values.is_external === 'false' && setShow(true)
    validation.values.is_external === 'true' && setShow(false)
  }, [validation.values.is_external])

  const handleSelectChangeTemplate = (selectedValues) => {
    const selectedOptionValues = selectedValues.map((option) => option.value)
    setSelectedTemplate(selectedOptionValues)
  }

  const title = `${t('Agencies')}: ${id ? t('Edit') : t('Create')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color='primary'>{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={'/external-agencies'} entity={entity} />

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/external-agencies'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/external-agencies'} title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className='needs-validation mt-4'
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row>
                {Object.keys(inputInitials).map((fieldKey) => {
                  console.log(
                    `inputInitials[${fieldKey}] => `,
                    inputInitials[fieldKey]
                  )
                  if (inputInitials[fieldKey].emptyLine)
                    return <Col key={`${fieldKey}`} className='col-12' />

                  const htmlForId = `validation-${fieldKey}`
                  return (
                    <Col
                      className={
                        inputInitials[fieldKey].fullWidth
                          ? 'col-12'
                          : 'col-auto'
                      }
                      key={`${fieldKey}`}
                    >
                      <FormGroup className='mb-3'>
                        <Label htmlFor={htmlForId}>
                          {t(inputInitials[fieldKey].label)}
                        </Label>
                        {inputInitials[fieldKey].options &&
                        inputInitials[fieldKey].options.length ? (
                          <select
                            name={`${fieldKey}`}
                            id={htmlForId}
                            className='form-select form-select-sm'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey]}
                          >
                            {inputInitials[fieldKey].options.map((option) => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <Input
                            name={`${fieldKey}`}
                            placeholder={inputInitials[fieldKey].placeholder}
                            type='text'
                            className='form-control form-control-sm'
                            id={htmlForId}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values[fieldKey] || ''}
                            invalid={
                              !!(
                                validation.touched[fieldKey] &&
                                validation.errors[fieldKey]
                              )
                            }
                          />
                        )}
                        {validation.touched[fieldKey] &&
                        validation.errors[fieldKey] ? (
                          <FormFeedback type='invalid'>
                            {validation.errors[fieldKey]}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {show && Boolean(params.get('id')) && (
                <>
                  {t('Template')}
                  <Select
                    isMulti
                    options={options}
                    onChange={handleSelectChangeTemplate}
                  />
                </>
              )}

              {!!updateError && (
                <Alert color='warning'>
                  <strong> {updateError.toString()} </strong>
                </Alert>
              )}
              {!!createError && (
                <Alert color='warning'>
                  <strong> {createError.toString()} </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className='text-nowrap'
                  color='primary'
                  style={{ backgroundColor: '#405189' }}
                  type='submit'
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
