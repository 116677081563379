import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import List from 'src/Components/Common/EntitiesView/List'
import Select from 'src/Components/Common/Select'
import ErrorPage from '../ErrorPage'

import Client from 'src/client'

import { getEntities } from 'src/store/actions'

const fieldsDescription = {
  name: { label: 'Employee' },
  group_name: {},
  stage: { label: 'Collection stage' },
  queue_count: { label: 'queue count' },
  contracts_assigned: {},
  contracts_processed: {},
  contracts_processed_queue: {},
  contracts_processed_queue_ratio: {},
  contacts: { label: 'touching' },
  ptp: { label: 'PTP' }
}

export const Supervisor = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [options, setOptions] = useState([{ name: '-', value: '-' }])
  const [fieldsFilter, setFieldsFilter] = useState({})
  const [collectorToAssign, setCollectorToAssign] = useState([])
  const [assignSuccess, setAssignSuccess] = useState([])
  const [userStages, setUserStages] = useState([])
  const [selectUserStage, setSelectUserStage] = useState(userStages[0])
  const [assignError, setAssignError] = useState([])

  const { list, listLoading, listError } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.SUPERVISOR_REPORTS].list,
      listLoading:
        state.entities[Client.ENTITY_TYPE.SUPERVISOR_REPORTS].listLoading,
      listError: state.entities[Client.ENTITY_TYPE.SUPERVISOR_REPORTS].listError
    }
  })

  const { list: listCollectors } = useSelector((state) => {
    return {
      list: state.entities[Client.ENTITY_TYPE.COLLECTORS].list,
      listLoading: state.entities[Client.ENTITY_TYPE.COLLECTORS].listLoading,
      listError: state.entities[Client.ENTITY_TYPE.COLLECTORS].listError
    }
  })

  /*  useEffect(() => {
    dispatch(getEntities(Client.ENTITY_TYPE.SUPERVISOR_REPORTS, null, '', 0, 1000));
  }, [dispatch])*/

  useEffect(() => {
    const selectOptions = [
      { name: '-', value: '-' },
      ...listCollectors.map((user) => ({
        ...user,
        name: `${user.surname} ${user.name} ${user.midname}`,
        value: user.id
      }))
    ]
    selectOptions.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1
    )
    setOptions(selectOptions)
    setCollectorToAssign(listCollectors.map(() => '-'))
  }, [listCollectors])

  useEffect(() => {
    /*clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`]);
    debounceTimers[`filtered-entities-${fieldsFilterJson}`] = setTimeout(() => {
      console.log(`request list with ${fieldsFilterJson}`);
      if (fieldsFilter.gender) fieldsFilter.gender = model.getGenderCode(fieldsFilter.gender);
      dispatch(getEntities(Client.ENTITY_TYPE.SUPERVISOR_REPORTS, null, fieldsFilter, 0, 500));
    }, DEBOUNCE_TIMEOUT);

    return ()=>{
      if (debounceTimers[`filtered-entities-${fieldsFilterJson}`]) {
        clearTimeout(debounceTimers[`filtered-entities-${fieldsFilterJson}`]);
      }
    }*/

    dispatch(
      getEntities(
        Client.ENTITY_TYPE.SUPERVISOR_REPORTS,
        null,
        fieldsFilter,
        0,
        1000
      )
    )
  }, [fieldsFilter])

  console.log('reports =>', list)
  const title = t("Supervisor's workplace")
  document.title = title

  if (listError) {
    return <ErrorPage title={title} error={listError} />
  }

  const bread = <BreadCrumb title={title} />

  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            <div className='live-preview'>
              <div className='table-responsive table-card'>
                <FieldsFilter
                  fields={{
                    username: { label: 'Employees' },
                    group_name: {},
                    stage: { label: 'Collection_stage' }
                  }}
                  applyFilter={(values) => setFieldsFilter(values)}
                  resetFilter={() => setFieldsFilter({})}
                />
                <div className='mt-3' />
                {listLoading ? (
                  <Spinner color='primary'>{t('Loading...')}</Spinner>
                ) : (
                  <List
                    style={{ width: 'fit-content' }}
                    list={list}
                    fieldsDescription={fieldsDescription}
                    className='table-sm align-middle table-nowrap mb-0'
                    hover
                    actions={list.map((item, rank) => {
                      const value = collectorToAssign[rank]

                      return (
                        <div key={`action-${rank}`}>
                          <div className='d-flex flex-row gap-2'>
                            <Button
                              size='sm'
                              disabled={value === '-'}
                              onClick={() => {
                                console.log('reassign', item.user_id, value)

                                const newError = [...assignError]
                                newError[rank] = ''
                                setAssignError(newError)

                                const newSuccess = [...assignSuccess]
                                newSuccess[rank] = false
                                setAssignSuccess(newSuccess)

                                Client.reassign(
                                  item.user_id,
                                  value,
                                  selectUserStage && selectUserStage.id
                                )
                                  .then((res) => {
                                    console.log('reassign::res=>', res)
                                    const newSuccess = [...assignSuccess]
                                    newSuccess[rank] = true
                                    setAssignSuccess(newSuccess)
                                  })
                                  .catch((err) => {
                                    console.log('reassign::err=>', err)
                                    const newError = [...assignError]
                                    newError[rank] = err
                                    setAssignError(newError)
                                  })
                              }}
                            >
                              {t('reassign_to')}
                            </Button>

                            <Select
                              options={options}
                              onChange={(collector) => {
                                const newCollectorToAssign = [
                                  ...collectorToAssign
                                ]
                                newCollectorToAssign[rank] = collector
                                setCollectorToAssign(newCollectorToAssign)

                                const newError = [...assignError]
                                newError[rank] = ''
                                setAssignError(newError)

                                const newSuccess = [...assignSuccess]
                                newSuccess[rank] = false
                                setAssignSuccess(newSuccess)
                                Client.getUserStagesCollector(collector).then(
                                  (userStages) => {
                                    setUserStages(userStages)
                                    setSelectUserStage(userStages[0])
                                  }
                                )
                              }}
                              value={value || '-'}
                            />

                            {userStages?.length > 1 && (
                              <Select
                                options={userStages}
                                onChange={(collector) => {
                                  setSelectUserStage(
                                    userStages.find(
                                      (item) => collector === item.name
                                    )
                                  )
                                }}
                                value={selectUserStage?.name}
                              />
                            )}
                          </div>

                          {assignError[rank] && (
                            <small className='text-danger'>
                              {assignError[rank].toString()}
                            </small>
                          )}

                          {assignSuccess[rank] && (
                            <small className='text-success'>{t('saved')}</small>
                          )}
                        </div>
                      )
                    })}
                  />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
