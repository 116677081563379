import {
  Button,
  ButtonGroup,
  Input,
  Label,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Client from 'src/client'
import { Link } from 'react-router-dom'
import Flatpickr from 'react-flatpickr'

export const CallResultModal = (props) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  // TODO: use only needed props intstead of <CallResultModal entity= -> <CallResultModal profileId=
  const { persons, profile_id, id, profile } = props.entity
  const [reasons, setReasons] = useState([])
  const [results, setResults] = useState([])
  const [errorText, setErrorText] = useState('')
  const [data, setData] = useState({
    profile_id,
    loan_id: id,
    contact_type: persons && persons.length ? persons[0].name : '',
    result: 'Жалоба',
    reason: 'Нет причины',
    amount_promise: '',
    date_promise: '',
    comment: '',
  })

  const [collapsed, setCollapsed] = useState(true)

  const toggle = () => setCollapsed((v) => !v)

  const getIdFromName = (list, name) =>
    list.find((item) => item.name === name)?.id

  const autoResizeInput = (event) => {
    event.target.style.height = '40px'
    event.target.style.height = event.target.scrollHeight + 'px'
  }

  useEffect(() => {
    Client.getReasons(getIdFromName(persons || [], data.contact_type)).then(
      (res) => {
        setReasons([{ id: 1000, name: 'Нет причины' }, ...res])
      },
    )
    Client.getResults(getIdFromName(persons || [], data.contact_type)).then(
      (res) => {
        setResults(res)
      },
    )
  }, [data.contact_type])

  const onSubmit = async () => {
    const {
      profile_id,
      loan_id,
      contact_type,
      result,
      reason,
      amount_promise,
      date_promise,
      comment,
    } = data
    const resultData = { loan_id, profile_id, contact_type, result }
    resultData.contact_type = getIdFromName(persons, contact_type)
    resultData.result = getIdFromName(results, result)

    if (reason !== 'Нет причины') {
      resultData.reason = getIdFromName(reasons, reason)
    }

    if (amount_promise) {
      resultData.amount_promise = amount_promise
    }

    if (date_promise && !date_promise.includes('T')) {
      resultData.date_promise = `${date_promise}T00:00:00`
    }

    if (comment) {
      resultData.comment = comment
    }

    if (props.isOrder) {
      resultData.is_order = true
    } else {
      resultData.is_order = false
    }

    if (props.collectionQueueSeq) {
      resultData.collection_queue_id = props.collectionQueueSeq
    }

    //console.log('resultData', resultData);
    setErrorText('')
    Client.postCallResult(resultData)
      .then(() => {
        if (props.collectionQueueSeq) {
          navigate('/debts-queue')
        } else {
          setErrorText('saved')
        }
      })
      .catch((error) => {
        setErrorText(error)
      })
  }

  const header = (
    <div className="d-flex flex-row justify-content-between w-100 p-3">
      <div className="modal-title">
        <span>{t('Fill out call result')}</span>
      </div>
    </div>
  )

  if (collapsed) {
    return (
      <div
        id="myModal"
        className="bg-light rounded shadow border"
        style={{
          position: 'fixed',
          right: 50,
          top: 200,
          zIndex: 1005,
        }}
      >
        <div
          style={{ position: 'relative' }}
          className="d-flex flex-column h-100"
        >
          <div className="text-center" role="button" onClick={toggle}>
            <small className="fw-medium text-decoration-underline">
              {t('expand')}
            </small>
          </div>
          {header}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className="bg-light rounded shadow border"
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          top: 50,
          zIndex: 1005,
        }}
      >
        <div
          style={{ position: 'relative' }}
          className="d-flex flex-column h-100"
        >
          <div className="text-center" role="button" onClick={toggle}>
            <small className="fw-medium text-decoration-underline">
              {t('collapse')}
            </small>
          </div>
          <ModalHeader>
            {t('Recording the result of a conversation with')}
            <br />
            <Link
              className="btn-md pt-0 text-decoration-underline"
              to={`/client-view?id=${profile.client.id}`}
            >
              {`${profile?.client?.last_name} ${profile?.client?.first_name} ${profile?.client?.middle_name}`}
            </Link>
          </ModalHeader>
          <div className="h-divider" />
          <ModalBody
            style={{
              overflow: 'auto',
              paddingTop: 0,
            }}
          >
            <div className="vstack d-flex align-items-center justify-content-center p-2">
              <div
                className="vstack mt-2 mb-0 align-self-center"
                style={{ width: '350px' }}
              >
                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'contact_type'}>
                    {t('Who were you talking to?')}
                  </Label>
                  <Input
                    id={'contact_type'}
                    value={data.contact_type}
                    type={'select'}
                    onChange={(evv) =>
                      setData({
                        ...data,
                        contact_type: persons.find(
                          (item) => item.name === evv.target.value,
                        ).name,
                      })
                    }
                  >
                    {persons.map((person) => (
                      <option key={person.id}>{person.name}</option>
                    ))}
                  </Input>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'call_result'}>{t('result')}</Label>
                  <Input
                    id={'call_result'}
                    value={data.result}
                    type={'select'}
                    onChange={(evv) =>
                      setData({
                        ...data,
                        result: results.find(
                          (item) => item.name === evv.target.value,
                        ).name,
                      })
                    }
                  >
                    {results.map((result) => (
                      <option key={result.id}>{result.name}</option>
                    ))}
                  </Input>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'reason'}>{t('reason')}</Label>
                  <Input
                    id={'reason'}
                    value={data.reason}
                    type={'select'}
                    onChange={(evv) =>
                      setData({
                        ...data,
                        reason: reasons.find(
                          (item) => item.name === evv.target.value,
                        ).name,
                      })
                    }
                  >
                    {reasons.map((reason) => (
                      <option key={reason.id}>{reason.name}</option>
                    ))}
                  </Input>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'comment'}>{t('Comment')}</Label>
                  <Input
                    onChange={(evv) =>
                      setData({ ...data, comment: evv.target.value })
                    }
                    value={data.comment}
                    style={{ height: '40px' }}
                    onInput={autoResizeInput}
                    id={'comment'}
                    type={'textarea'}
                  />
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'amount_promise'}>
                    {t('amount_promise')}
                  </Label>
                  <Input
                    id={'amount_promise'}
                    value={String(data.amount_promise)}
                    onChange={(e) => {
                      const value = e.target.value
                        .split('')
                        .filter((d) =>
                          [
                            '0',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '.',
                          ].includes(d),
                        )
                        .join('')

                      setData({
                        ...data,
                        amount_promise: value,
                      })
                    }}
                  />
                </div>

                <div style={{}}>
                  <Label>{t('date_promise')}</Label>
                  <Flatpickr
                    className="form-control"
                    readOnly={false}
                    id="date_promise"
                    onChange={(e) => {
                      //console.log('Flatpickr::onChange', e);
                    }}
                    options={{
                      minDate: 'today',
                      onChange: (selectedDates, dateStr) => {
                        //console.log('Flatpickr::selectedDates, dateStr', selectedDates, dateStr);
                        setData({ ...data, date_promise: dateStr })
                      },
                      dateFormat: 'Y-m-d',
                    }}
                  />
                </div>

                {errorText && (
                  <span
                    className={
                      errorText === 'saved' ? 'text-success' : 'text-danger'
                    }
                  >
                    {t(errorText)}
                  </span>
                )}
              </div>
              <ButtonGroup>
                <Button
                  style={{ marginLeft: '10px' }}
                  className="mt-3"
                  type={'submit'}
                  onClick={onSubmit}
                >
                  {t('Send')}
                </Button>
              </ButtonGroup>
            </div>
          </ModalBody>
        </div>
      </div>
    </>
  )
}
