import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Table } from 'reactstrap'
import { Link } from 'react-router-dom'

import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import {
  useGetAllTopicQuery,
  useGetClaimChannelTypesQuery,
  useGetMyClaimsQuery
} from 'src/services/claims'
import model from 'src/model'
import BreadCrumb from 'src/Components/Common/BreadCrumb'

import FieldsFilter from 'src/Components/Common/FieldsFilter'
import { LIST_LIMIT } from 'src/client'
import { UseQueryHookArgs } from 'src/services/api'
import { Pagination } from './Components/pagination'

type FieldClaims = {
  [key: string]: {
    label: string
    isDate?: boolean
    isLink?: boolean
  }
}

const fieldsDescription: FieldClaims = {
  id: { label: '', isLink: true },
  claim_number: { label: 'claim_number' },
  fio_client: { label: 'fio_client' },
  claim_topic_name: { label: 'claim_topic_name' },
  claim_subtopic_name: { label: 'claim_subtopic_name' },
  created_at: { label: 'created_at', isDate: true },
  updated_at: { label: 'updated_at', isDate: true },
  status: { label: 'status' },
  channel_type: { label: 'channel_type' },
  days_to_reply: { label: 'days_to_reply' },
  claim_subtopic_priority: { label: 'priority' },
  plan_date: { label: 'plan_date', isDate: true }
}

export const MyClaims = () => {
  const { t } = useTranslation()
  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetMyClaimsQuery>
  >({})

  const {
    data: claims,
    isFetching,
    error,
    isError,
    refetch
  } = useGetMyClaimsQuery({
    limit: LIST_LIMIT,
    offset: 0,
    ...fieldsFilter
  })

  const { data: channelTypes } = useGetClaimChannelTypesQuery()

  const { data: allTopic } = useGetAllTopicQuery([])

  useEffect(() => {
    refetch()
  }, [refetch, fieldsFilter])

  const title = t('My claims')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  if (!claims) {
    return <LoadingPage title={title} backLink={undefined} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <FieldsFilter
            fields={{
              id: { label: 'claim_id' },
              claimNumber: { label: 'claim_number' },
              clientId: { label: 'client_id' },
              chanelType: {
                label: 'channel_type',
                options: channelTypes
              },
              createdAt: { date: true, label: 'created_at' },
              updatedAt: { date: true, label: 'updated_at' },
              status: {
                options: [
                  'Получено',
                  'В работе',
                  'Темы назначены',
                  'Запрошены данные',
                  'Закрыто',
                  'Темы обработаны',
                  'На проверке'
                ]
              },
              claimTopicId: {
                label: 'claim_topic_id',
                optionsValue: allTopic
                  ? allTopic
                      .filter((el) => !el.parent_topic_id)
                      .map((topic) => {
                        return { value: topic.id, name: topic.topic_name }
                      })
                  : null
              },
              planDate: { label: 'plan_date', date: true },
              emailTo: { label: 'email_to' },
              emailFrom: { label: 'email_from' }
            }}
            applyFilter={(values: typeof fieldsFilter) =>
              // FieldsFilter runs applyFilter function on reset instead of resetFilter
              setFieldsFilter(values)
            }
            resetFilter={() => setFieldsFilter({})}
          />
          <Pagination
            list={claims}
            setFieldsFilter={setFieldsFilter}
            fieldsFilter={fieldsFilter}
          />
          {isFetching ? (
            <Loader />
          ) : (
            <Table className={'table-sm align-middle mb-0'}>
              <thead>
                <tr>
                  {Object.keys(fieldsDescription).map((key) => {
                    return (
                      <th key={key} className='pe-4' scope='col'>
                        {t(fieldsDescription[key].label)}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {claims.map((claim) => {
                  return (
                    <tr key={`${claim.id}`}>
                      {Object.keys(fieldsDescription).map((key) => (
                        <td
                          key={`${claim.id}-${key}`}
                          align={'left'}
                          className={`pe-4`}
                          style={{
                            backgroundColor:
                              claim.status === 'Закрыто' ||
                              claim.status === 'Темы обработаны'
                                ? 'rgba(102, 145, 231, 0.18)'
                                : undefined
                          }}
                        >
                          {fieldsDescription[key].isLink ? (
                            <Link
                              to={
                                claim.channel_type === 'proxy'
                                  ? `/claim-view?id=${claim.id}`
                                  : `/main-claim-view?id=${claim.id}`
                              }
                              className='fw-medium'
                            >
                              <i className='ri-eye-line fs-15' />
                            </Link>
                          ) : fieldsDescription[key].label === 'fio_client' ? (
                            `${claim.client_surname} ${claim.client_name} ${claim.client_midname}`
                          ) : (
                            model.fieldValueToText(
                              key,
                              claim,
                              fieldsDescription
                            )
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
