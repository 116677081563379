import { Alert } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import model from 'src/model'
import { FC } from 'react'

type LimitNoticeProps = {
  limit?: number
}

export const LimitNotice: FC<LimitNoticeProps> = ({
  limit = model.LIST_LIMIT,
}) => {
  const { t } = useTranslation()

  return (
    <div className="ps-3" style={{ width: 'fit-content' }}>
      <Alert color="info">
        {t('use_filter_message').replace('50', limit.toString())}
      </Alert>
    </div>
  )
}

type OffsetNoticeProps = {
  offset: number
  limit: number
}

export const OffsetNotice: FC<OffsetNoticeProps> = ({ offset, limit }) => {
  const { t } = useTranslation()

  return (
    <div className="ps-3" style={{ width: 'fit-content' }}>
      <Alert color="info">
        {t('shown_offset_limit_message')
          .replace('$offset_start', offset.toString())
          .replace('$offset_end', (offset + limit).toString())}
      </Alert>
    </div>
  )
}

export default LimitNotice
