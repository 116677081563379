import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody } from 'reactstrap'

import Loader from 'src/Components/Common/Loader'
import ErrorPage from '../ErrorPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import FieldsFilter from 'src/Components/Common/FieldsFilter'
import List from 'src/Components/Common/EntitiesView/List'
import { LIST_LIMIT } from 'src/client'
import { UseQueryHookArgs } from 'src/services/api'
import {
  useDownloadBkiFileMutation,
  useGetBkiListQuery
} from 'src/services/bkiSentFile'

export const BkiSent = () => {
  const { t } = useTranslation()

  const [fieldsFilter, setFieldsFilter] = useState<
    UseQueryHookArgs<typeof useGetBkiListQuery>
  >({})

  const {
    data: bkiList,
    isFetching,
    isError,
    error
  } = useGetBkiListQuery({
    limit: LIST_LIMIT,
    offset: 0,
    ...fieldsFilter
  })

  const [DownloadBkiFile] = useDownloadBkiFileMutation()

  const downloadFile = (item: any) => {
    DownloadBkiFile({ file_name: item.file_name, bureau: item.company }).then(
      (blob) => {
        if ('data' in blob) {
          const url = window.URL.createObjectURL(new Blob([blob.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${item.file_name}.xml`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      }
    )
  }

  const title = t('Viewing files in BKI')

  if (isError) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <FieldsFilter
            fields={{
              bureau: {
                label: t('Company'),
                optionsValue: [
                  { value: 'equifax', name: 'Эквифакс' },
                  { value: 'nbki', name: 'НБКИ' }
                ]
              },
              file_name: {},
              loan_uuid_cbr: {},
              event_name: {}
            }}
            applyFilter={(values: typeof fieldsFilter) =>
              setFieldsFilter(values)
            }
            resetFilter={() => setFieldsFilter({})}
          />
          {isFetching ? (
            <Loader />
          ) : (
            bkiList && (
              <List
                list={bkiList.map((item) => ({ ...item }))}
                className={undefined}
                style={{ width: 'fit-content' }}
                fieldsDescription={{
                  company: { label: t('Company') },
                  created_at: { isDate: true },
                  file_name: {},
                  event_name: {},
                  loan_uuid_cbr: {}
                }}
                hover
                actions={bkiList.map((item: any, rank) => {
                  return (
                    <Button
                      key={`action-${rank}`}
                      size='sm'
                      onClick={() => downloadFile(item)}
                    >
                      {t('Download')}
                    </Button>
                  )
                })}
              />
            )
          )}
        </CardBody>
      </Card>
    </div>
  )
}
