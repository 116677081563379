import { api, omitBlankEntries } from './api'

type Call = any

type CallPerson = {
  id: number
  name: 'Должник' | 'Третье лицо' | 'Неизвестно'
}

type Group = {
  id: number
  name: string
  created_at: string
}

export const callingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalls: builder.query<Call[], string>({
      query: (id) => `crm/v1/calling/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'Calls', id }],
    }),
    getCallPersons: builder.query<CallPerson[], void>({
      query: () => `crm/v1/calling/person`,
    }),
    getGroups: builder.query<Group[], string>({
      query: () => `crm/v1/calling/groups`,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Groups', id }) as const),
        { type: 'Groups' as const, id: 'LIST' },
      ],
    }),
    updateGroup: builder.mutation<
      void,
      Pick<Group, 'id'> & { user_ids: string[]; new_name: string }
    >({
      query: ({ id, ...body }) => ({
        url: `crm/v1/calling/groups/${id}`,
        method: 'PUT',
        body: omitBlankEntries(body),
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: 'Groups', id: request.id },
      ],
    }),
    deleteSegment: builder.mutation<any, string | null>({
      query: (id) => ({
        url: `crm/v1/calling/stages/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetCallsQuery,
  useGetCallPersonsQuery,
  useGetGroupsQuery,
  useUpdateGroupMutation,
  useDeleteSegmentMutation,
} = callingApi
