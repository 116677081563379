import { useState } from 'react'
import classnames from 'classnames'

import EntityInfo from './EntityInfo'

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

const TabsGroup = ({ items }) => {
  const [active, setActive] = useState(0)

  const handleActive = (rank) => {
    setActive(rank)
  }

  if (!items || !items.length) return null

  return (
    <div>
      <Nav tabs className="nav-tabs nav-border-top mb-3">
        {items.map((item, rank) => {
          if (!item.entityId && !item.entity) return null

          return (
            <NavItem key={`nav-${item.id}-${rank}`}>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({
                  active: active === rank,
                })}
                onClick={() => handleActive(rank)}
              >
                {false && <i className={item.icon} />}
                {item.title}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={active}>
        {items.map((item, rank) => (
          <TabPane tabId={rank} key={`${item.id}-${rank}`}>
            <div className="d-flex">
              {!!item.icon && (
                <div className="flex-shrink-0">
                  <i className={`${item.icon} text-success`}></i>
                </div>
              )}
              <div className="flex-grow-1 ms-0">
                <EntityInfo {...item} visible={active === rank} />
              </div>
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default TabsGroup
