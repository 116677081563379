import { FC, useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import Table4Columns from '../Common/EntitiesView/Table4Columns'

interface LoanTableProps {
  hasData?: boolean
  entity: any
  columnsDescriptionHead: any
  columnsDescriptionMain?: any
  fieldsDescriptionHead?: any
  fieldsDescriptionMain?: any
}

const columnsDescriptionOperations = [
  ['accrued_principal', 'accrued_interest'],
  ['accrued_penalty', 'accrued_overpayment'],
  ['debt_principal', 'debt_interest'],
  ['debt_penalty', 'debt_overpayment'],
  ['paid_principal', 'paid_interest'],
  ['paid_penalty', 'paid_overpayment'],
  ['writeoff_principal', 'writeoff_interest'],
  ['writeoff_penalty', 'writeoff_overpayment']
]

export const LoanTableKPK: FC<LoanTableProps> = ({
  entity,
  columnsDescriptionHead,
  columnsDescriptionMain,
  fieldsDescriptionHead,
  fieldsDescriptionMain
}) => {
  const [operationsList, setOperationsList] = useState({})

  useEffect(() => {
    const operationTypes = ['Principal', 'Interest', 'Penalty', 'Overpayment']
    const categories = ['accrued', 'debt', 'paid', 'writeoff']

    const initialResult = categories.reduce((acc, category) => {
      operationTypes.forEach((type) => {
        //@ts-ignore
        acc[`${category}_${type.toLowerCase()}`] = 0
      })
      return acc
    }, {})

    const result = { ...initialResult }

    categories.forEach((category) => {
      entity?.operations?.[category]?.forEach((value: any) => {
        const key = `${category}_${value.operation_type.toLowerCase()}`
        //@ts-ignore
        if (result[key] !== undefined) {
          //проверка нужна т.к. строка 64 (result[key] = 0 => js думает что 0 = false)
          //@ts-ignore
          result[key] = value.amount
        }
      })
    })
    setOperationsList(result)
  }, [entity])

  if (!entity) return <Container fluid>Нет данных</Container>

  // Principal - од
  // Interest - проценты
  // Penalty - штрафы
  // Overpayment - переплата
  // =======
  // accurued начислено
  // debt долг
  // paid оплачено
  // writeoff списано

  return (
    <>
      <Container fluid>
        <h6>Данные по компании / продукту</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionHead}
          fieldsDescription={fieldsDescriptionHead}
          entity={{
            ...entity
          }}
        />
      </Container>
      <Container className='mt-3' fluid>
        <h6>Данные по займу</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionMain}
          fieldsDescription={fieldsDescriptionMain}
          entity={{
            ...entity
          }}
        />
      </Container>
      <Container className='mt-3' fluid>
        <h6>Операции</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionOperations}
          fieldsDescription={[]}
          entity={{
            ...operationsList
          }}
        />
      </Container>
    </>
  )
}
