import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { getEntities } from 'src/store/actions'

import Client from 'src/client'
import model from 'src/model'

import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'

const mappingOrderValue = (order) => {
  if (!order || !order.order) return '-'
  const strOrder = order.order == 1 ? 'asc' : order.order == '-1' ? 'desc' : '-'
  return `${strOrder}, priority: ${order.priority}`
}

const mappingFilterValue = (list) => {
  if (!list || !list.length) return '-'
  return list.join(', ')
}

const fieldsDescription = {
  //order
  order_income: {
    type: 'order',
    label: 'order_income',
    mappingValue: mappingOrderValue,
  },
  order_login_date: {
    type: 'order',
    label: 'order_login_date',
    mappingValue: mappingOrderValue,
  },
  order_promise_date: {
    type: 'order',
    label: 'order_promise_date',
    mappingValue: mappingOrderValue,
  },
  order_day_past_due_promise: {
    type: 'order',
    label: 'order_day_past_due_promise',
    mappingValue: mappingOrderValue,
  },
  order_prolongation_count: {
    type: 'order',
    label: 'order_prolongation_count',
    mappingValue: mappingOrderValue,
  },
  order_loan_stage: {
    type: 'order',
    label: 'order_loan_stage',
    mappingValue: mappingOrderValue,
  },
  order_collection_score: {
    type: 'order',
    label: 'order_collection_score',
    mappingValue: mappingOrderValue,
  },
  order_dpd: {
    type: 'order',
    label: 'order_dpd',
    mappingValue: mappingOrderValue,
  },
  order_amount_debt: {
    type: 'order',
    label: 'order_amount_debt',
    mappingValue: mappingOrderValue,
  },
  order_timezone: {
    type: 'order',
    label: 'order_timezone',
    mappingValue: mappingOrderValue,
  },

  //filter
  login_date: {
    type: 'filter',
    label: 'login_date',
    mappingValue: mappingFilterValue,
  },
  promise_date: {
    type: 'filter',
    label: 'promise_date',
    mappingValue: mappingFilterValue,
  },
  income: { type: 'filter', label: 'income', mappingValue: mappingFilterValue },
  day_past_due_promise: {
    type: 'filter',
    label: 'day_past_due_promise',
    mappingValue: mappingFilterValue,
  },
  prolongation_count: {
    type: 'filter',
    label: 'prolongation_count',
    mappingValue: mappingFilterValue,
  },
  loan_stage: {
    type: 'filter',
    label: 'loan_stage',
    mappingValue: mappingFilterValue,
  },
  collection_score: {
    type: 'filter',
    label: 'collection_score',
    mappingValue: mappingFilterValue,
  },
  dpd: { type: 'filter', label: 'dpd', mappingValue: mappingFilterValue },
  amount_debt: {
    type: 'filter',
    label: 'amount_debt',
    mappingValue: mappingFilterValue,
  },
  timezone: {
    type: 'filter',
    label: 'timezone',
    mappingValue: mappingFilterValue,
  },
}

export const QueueView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const [collectorsByStage] = useState([])
  const [filterName, setFilterName] = useState([])
  const { entity, entityLoading, entityError } = useSelector((state) => ({
    entity: state.entities[Client.ENTITY_TYPE.QUEUES].entity
      ? state.entities[Client.ENTITY_TYPE.QUEUES].entity[0]
      : null,
    entityLoading: state.entities[Client.ENTITY_TYPE.QUEUES].entityLoading,
    entityError: state.entities[Client.ENTITY_TYPE.QUEUES].entityError,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    setId(id || null)
    if (id) {
      dispatch(getEntities(Client.ENTITY_TYPE.QUEUES, id))
      //Client.getStageCollectors(id).then((res) => {
      //  setCollectorsByStage(res.map(collector => ({...collector, FIO: `${collector.surname} ${collector.name} ${collector.midname}`.toLowerCase()})));
      //})
    } else setParamError('No entity id found')
  }, [])

  useEffect(() => {
    setPageReady(!!entity)
  }, [entity])

  const title = `${t('Queues')}: ${t('View')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const metaBar = (
    <MetaBar
      backLink={'/queues'}
      editLink={entity ? `/queues-edit?id=${entity.id}` : null}
      entity={entity}
    />
  )

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/queues'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/queues'} title={title} />
  }

  console.log('entity =>', entity)
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              <div>
                <b>{t('queue_name')}:</b>
              </div>
              <div>
                {t(
                  model.fieldValueToText(
                    'queue_name',
                    entity,
                    fieldsDescription,
                  ),
                )}
              </div>
            </Row>
            <Row className="mt-3">
              <div>
                <b>{t('Users')}:</b>
              </div>
              <div>
                {entity.users?.length > 0
                  ? entity.users.map((user) => <div key={user}>{user}</div>)
                  : '-'}
              </div>
            </Row>
            <Row className="mt-3">
              <div>
                <b>{t('Groups')}:</b>
              </div>
              <div>
                {entity.groups?.length > 0
                  ? entity.groups.map((group) => <div key={group}>{group}</div>)
                  : '-'}
              </div>
            </Row>

            <Row className="mt-3">
              <div>
                <b>{t('Seq')}:</b>
              </div>
              <div>
                {t(model.fieldValueToText('seq', entity, fieldsDescription))}
              </div>
            </Row>

            <Row className="mt-3">
              <div>
                <b>{t('Order')}:</b>
              </div>
              {Object.keys(fieldsDescription)
                .filter((key) => fieldsDescription[key].type === 'order')
                .map((fieldName) => {
                  return (
                    <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                      <Label htmlFor={fieldName}>
                        {t(fieldsDescription[fieldName].label || fieldName)}
                      </Label>
                      <Input
                        type="text"
                        className="form-control form-control-sm"
                        value={t(
                          model.fieldValueToText(
                            fieldName,
                            entity,
                            fieldsDescription,
                          ),
                        )}
                        disabled
                      />
                    </Col>
                  )
                })}
            </Row>
            <Row className="mt-3">
              <div>
                <b>{t('Filters')}:</b>
              </div>
              {Object.keys(fieldsDescription)
                .filter((key) => fieldsDescription[key].type === 'filter')
                .map((fieldName) => {
                  return (
                    <Col className={'col-12 mt-2 mb-2'} key={`${fieldName}`}>
                      <Label htmlFor={fieldName}>
                        {t(fieldsDescription[fieldName].label || fieldName)}
                      </Label>
                      <Input
                        type="text"
                        className="form-control form-control-sm"
                        value={model.fieldValueToText(
                          fieldName,
                          entity,
                          fieldsDescription,
                        )}
                        disabled
                      />
                    </Col>
                  )
                })}
            </Row>
            {collectorsByStage.length > 0 && (
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className={`ms-0 fs-16`}>{t('Employees')}</span>
                <Input
                  onChange={(evv) =>
                    setFilterName(evv.target.value.toLowerCase())
                  }
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  placeholder={t('Enter name')}
                  className="form-control form-control-sm"
                />
                {collectorsByStage
                  .filter((col) => col.FIO.includes(filterName))
                  .map((collector) => (
                    <p key={collector.id}>
                      {collector.surname} {collector.name} {collector.midname}
                    </p>
                  ))}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
