import {Input, Label} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import './select.scss'

export const Select = ({label, value, onChange, onError}) => {
    const {t} = useTranslation();
    const [showOptions, setShowOptions] = useState(false);
    const [addressString, setAddressString] = useState(value);
    const [addressOptions, setAddressOptions] = useState([]);
    useEffect(() => {
        if (value !== addressString) {
            onError(true)
        } else {
            onError(false)
        }
    }, [addressString])

    const fetchDadata = async (query) => {
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
        let token = "59022ffd74cc4d28b69b211970a79d5e50c74016";
        let secret = "2757838c50388eb7c9e2a84cd631ee31f6a452e9";

        let options = {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token,
                "X-Secret": secret
            },
            body: JSON.stringify({query, count: 5})
        }

        return await fetch(url, options)
            .then(response => response.text())
            .then(result => JSON.parse(result).suggestions);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const words = addressString.trim().split(' ');
            if (words.length >= 2 && addressString) {
                fetchDadata(addressString).then((resolve) => setAddressOptions(resolve));
            }
        }, 750);

        return () => clearTimeout(timeoutId);
    }, [addressString]);
    return (
        <>
            <Label>{label}</Label>
            <Input
                type="text"
                className="form-control form-control-sm"
                onChange={(evv) => setAddressString(evv.target.value.trim())}
                onFocus={() => setShowOptions(true)}
                value={addressString}
                invalid={value !== addressString}
            />
            {
                addressOptions.length === 0 && <span style={{color: 'red'}}>{t('please change your request')}</span>
            }
            {
                value !== addressString && addressOptions.length > 0 && <span style={{color: 'red'}}>{t('Select an item from the list below')}</span>
            }
            {
                showOptions && addressOptions.length > 0 && (
                    <div style={{border: '1px solid #cacfd5', borderRadius: '0.25rem'}}>
                        {
                            addressOptions.map(item => (
                                <div className={'select-option'} key={`${label}-${item.value}`} onClick={() => {
                                    onChange(item);
                                    setAddressString(item.value);
                                    setShowOptions(false);
                                }}>{item.value}</div>
                            ))
                        }
                    </div>
                )
            }
        </>
    )
}