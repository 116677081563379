import List from './List'
import { useTranslation } from 'react-i18next'
import { useGetLoanOperationsQuery } from 'src/services/loans'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const fieldsDescription = {
  id: { isShortId: false },
  created_at: { isDateTime: true },
  operation_type: {},
  direction: {},
  amount: { isAmount: true, rightAligned: true }
}

const EntityContent = ({ entityId, style, visible }) => {
  const { t } = useTranslation()
  const { data: operations, error } = useGetLoanOperationsQuery(entityId, {
    skip: !visible
  })
  return (
    <>
      {error && <DangerAlert error={error} />}
      {operations?.length ? (
        <List
          list={operations}
          fieldsDescription={fieldsDescription}
          style={style}
        />
      ) : (
        <small>{t('No data')}</small>
      )}
    </>
  )
}

export default EntityContent
