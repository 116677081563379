import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'

import { formatAPIDateTime, formatUIDate } from 'src/utils'

import { createTransaction } from 'src/store/actions'

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const initials = {
  loan_id: { label: 'loan', initial: '', placeholder: '' },
  amount: { label: 'amount', initial: '', placeholder: '' },
  transaction_id: { label: 'transaction_id', initial: '', placeholder: '' },
  transaction_date: {
    label: 'transaction_date',
    initial: /*new Date()*/ null,
    placeholder: '',
    date: true,
  },
  //direction: {label: "direction", initial: "", placeholder: "-"},
  //is_accepted: {label: "is_accepted", initial: "-", placeholder: "-"},
  comment: { label: 'comment', initial: '', placeholder: '' },
}

export const PaymentEdit = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const backlink = '/payments'

  const navigate = useNavigate()

  const [initialFormValues, setInitialFormValues] = useState({})
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [creating, setCreating] = useState(false)

  const { createLoading, createError } = useSelector((state) => ({
    createError: state.transactions.createError,
    createLoading: state.transactions.createLoading,
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('loan_id')

    const initialValues = {}
    Object.keys(initials).forEach((key) => {
      initialValues[key] = initials[key].initial
    })
    initialValues['loan_id'] = id || ''
    setInitialFormValues(initialValues)

    const fp_transaction_date = document.querySelector(
      '#validation-transaction_date',
    )
    if (fp_transaction_date) {
      console.log('fp_transaction_date=>', fp_transaction_date._flatpickr)
      fp_transaction_date._flatpickr.setDate(
        initials['transaction_date'].initial,
      )
    }
  }, [props.location])

  useEffect(() => {
    if (!creating) return

    if (createLoading) {
      setSuccess(false)
      setError(false)
      return
    }

    setCreating(false)

    if (createError) {
      setError(createError)
    } else {
      toast.success(t('Operation success'), {
        onClose: () => navigate(backlink),
      })
      setSuccess(true)
    }
  }, [createLoading, createError])

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      console.log('values (createEntity)', values)
      setCreating(true)
      dispatch(createTransaction(values))
    },
  })

  const pageTitle = t('Create_payment')
  document.title = t(pageTitle)
  const bread = <BreadCrumb title={pageTitle} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>
  let metaBar = <MetaBar backLink={backlink} />

  console.log('initialFormValues', initialFormValues)

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className="mt-3">
              <Form
                className="needs-validation"
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  {Object.keys(initials).map((fieldKey) => {
                    const htmlForId = `validation-${fieldKey}`
                    return (
                      <Col md="4" key={`${fieldKey}`}>
                        <FormGroup className="mb-3">
                          <Label htmlFor={htmlForId}>
                            {t(initials[fieldKey].label)}
                          </Label>
                          {initials[fieldKey].options &&
                          initials[fieldKey].options.length ? (
                            <select
                              name={`${fieldKey}`}
                              id={htmlForId}
                              className="form-select form-select-sm"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values[fieldKey] || ''}
                            >
                              {initials[fieldKey].options.map((option) => (
                                <option
                                  key={option.name}
                                  defaultValue={option.defaultValue}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          ) : initials[fieldKey].date ? (
                            <Flatpickr
                              name={`${fieldKey}`}
                              id={htmlForId}
                              className="form-control form-control-sm"
                              options={{
                                enableTime: true,
                                time_24hr: true,
                                dateFormat: 'd-m-Y H:i',
                                defaultDate: [
                                  formatUIDate(initials[fieldKey].initial),
                                ],
                                onChange: (selectedDates, dateStr) => {
                                  console.log(
                                    'selectedDate, dateStr',
                                    selectedDates,
                                    dateStr,
                                  )
                                  validation.setFieldValue(
                                    fieldKey,
                                    formatAPIDateTime(selectedDates[0]),
                                  )
                                },
                              }}
                            />
                          ) : (
                            <Input
                              name={`${fieldKey}`}
                              placeholder={initials[fieldKey].placeholder}
                              type="text"
                              className="form-control form-control-sm"
                              id={htmlForId}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values[fieldKey] || ''}
                              invalid={
                                !!(
                                  validation.touched[fieldKey] &&
                                  validation.errors[fieldKey]
                                )
                              }
                            />
                          )}
                          {validation.touched[fieldKey] &&
                          validation.errors[fieldKey] ? (
                            <FormFeedback type="invalid">
                              {validation.errors[fieldKey]}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    )
                  })}
                </Row>

                {!!error && (
                  <Alert color="warning">
                    <strong> {error.toString()} </strong>
                  </Alert>
                )}

                {!!success && (
                  <Alert color="success">
                    <strong> {t('Created')} </strong>
                  </Alert>
                )}

                {createLoading ? (
                  loading
                ) : (
                  <Button
                    className="text-nowrap"
                    color="primary"
                    style={{ backgroundColor: '#405189' }}
                    type="submit"
                    disabled={!validation.dirty}
                  >
                    {t('Create')}
                  </Button>
                )}
              </Form>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
