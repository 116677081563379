import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'

import Client from 'src/client'
import InputItem from './InputItem'

import { getEntities, updateEntities, createEntities } from 'src/store/actions'

import { formatAPIDate } from 'src/utils'

import Select from 'react-select'

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Alert,
  Spinner,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

const booleanOptions = [
  { name: '-', value: '-' },
  { name: 'asc', value: 1 },
  { name: 'desc', value: -1 },
]
const inputInitials = {
  //who
  users: { type: 'common', label: 'collector_fio', initial: [], isList: true },
  groups: { type: 'common', label: 'group_name', initial: [], isList: true },
  queue_name: {
    type: 'common',
    label: 'queue_name',
    initial: '',
    style: { maxWidth: 240 },
  },
  seq: {
    type: 'common',
    label: 'seq',
    initial: 0,
    isNumber: true,
    style: { maxWidth: 240 },
  },

  //order
  order_income: {
    type: 'order',
    order: {
      label: 'order_income',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_login_date: {
    type: 'order',
    order: {
      label: 'order_login_date',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_promise_date: {
    type: 'order',
    order: {
      label: 'order_promise_date',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_day_past_due_promise: {
    type: 'order',
    order: {
      label: 'order_day_past_due_promise',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_prolongation_count: {
    type: 'order',
    order: {
      label: 'order_prolongation_count',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_loan_stage: {
    type: 'order',
    order: {
      label: 'order_loan_stage',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_collection_score: {
    type: 'order',
    order: {
      label: 'order_collection_score',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_dpd: {
    type: 'order',
    order: {
      label: 'order_dpd',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_amount_debt: {
    type: 'order',
    order: {
      label: 'order_amount_debt',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
    priority: { label: 'priority', initial: 0, isNumber: true },
  },
  order_timezone: {
    type: 'order',
    priority: { label: 'priority', initial: 0, isNumber: true },
    order: {
      label: 'order_timezone',
      initial: '-',
      options: booleanOptions,
      isNumber: true,
    },
  },

  //filter
  login_date: {
    type: 'filter',
    label: 'login_date',
    initial: [formatAPIDate(new Date()), formatAPIDate(new Date())],
    dateRange: true,
    isOptional: true,
  },
  promise_date: {
    type: 'filter',
    label: 'promise_date',
    initial: [formatAPIDate(new Date()), formatAPIDate(new Date())],
    dateRange: true,
    isOptional: true,
  },
  income: {
    type: 'filter',
    label: 'income',
    initial: [0, 200000],
    min: 0,
    max: 200000,
    step: 1000,
    numberRange: true,
    isOptional: true,
  },
  day_past_due_promise: {
    type: 'filter',
    label: 'day_past_due_promise',
    initial: [0, 365],
    min: 0,
    max: 365,
    step: 1,
    numberRange: true,
    isOptional: true,
  },
  prolongation_count: {
    type: 'filter',
    label: 'prolongation_count',
    initial: [0, 50],
    min: 0,
    max: 50,
    step: 1,
    numberRange: true,
    isOptional: true,
  },
  loan_stage: {
    type: 'filter',
    label: 'loan_stage',
    initial: [0, 10],
    min: 0,
    max: 10,
    step: 1,
    numberRange: true,
    isOptional: true,
  },
  collection_score: {
    type: 'filter',
    label: 'collection_score',
    initial: [0, 200],
    min: 0,
    max: 200,
    step: 1,
    numberRange: true,
    isOptional: true,
  },
  dpd: {
    type: 'filter',
    label: 'dpd',
    initial: [],
    isList: true,
    isNumber: true,
    isOptional: true,
  },
  amount_debt: {
    type: 'filter',
    label: 'amount_debt',
    initial: [0, 100000],
    min: 0,
    max: 100000,
    step: 1000,
    numberRange: true,
    isOptional: true,
  },
}

export const QueueEdit = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [id, setId] = useState(null)
  const [entity, setEntity] = useState(null)
  const [pageReady, setPageReady] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(null)
  const [successful] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collectors, setCollectors] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groups, setGroups] = useState([])
  const selectAllOption = { value: 'selectAll', label: 'Выбрать все' }
  const options = [
    { value: 'UTC+2', label: 'UTC+2' },
    { value: 'UTC+3', label: 'UTC+3' },
    { value: 'UTC+4', label: 'UTC+4' },
    { value: 'UTC+5', label: 'UTC+5' },
    { value: 'UTC+6', label: 'UTC+6' },
    { value: 'UTC+7', label: 'UTC+7' },
    { value: 'UTC+8', label: 'UTC+8' },
    { value: 'UTC+9', label: 'UTC+9' },
    { value: 'UTC+10', label: 'UTC+10' },
    { value: 'UTC+11', label: 'UTC+11' },
    { value: 'UTC+12', label: 'UTC+12' },
  ]
  const [selectedTimezone, setSelectedTimezone] = useState([])

  const {
    entityLoaded,
    entityLoading,

    updateLoading,
    updateError,
    createError,
    createLoading,
  } = useSelector((state) => ({
    entityLoaded: state.entities[Client.ENTITY_TYPE.QUEUES].entity?.length
      ? state.entities[Client.ENTITY_TYPE.QUEUES].entity[0]
      : null,
    entityLoading: state.entities[Client.ENTITY_TYPE.QUEUES].entityLoading,
    entityError: state.entities[Client.ENTITY_TYPE.QUEUES].entityError,
    updateError: state.entities[Client.ENTITY_TYPE.QUEUES].updateError,
    createError: state.entities[Client.ENTITY_TYPE.QUEUES].createError,
    updateLoading: state.entities[Client.ENTITY_TYPE.QUEUES].updateLoading,
    createLoading: state.entities[Client.ENTITY_TYPE.QUEUES].createLoading,
  }))

  const { list: listCollectors, listLoading: listUsersLoading } = useSelector(
    (state) => {
      return {
        list: state.entities[Client.ENTITY_TYPE.COLLECTORS].list,
        listLoading: state.entities[Client.ENTITY_TYPE.COLLECTORS].listLoading,
        listError: state.entities[Client.ENTITY_TYPE.COLLECTORS].listError,
      }
    },
  )

  const { list: listGroups, listLoading: listGroupsLoading } = useSelector(
    (state) => {
      return {
        list: state.entities[Client.ENTITY_TYPE.GROUPS].list,
        listLoading: state.entities[Client.ENTITY_TYPE.GROUPS].listLoading,
        listError: state.entities[Client.ENTITY_TYPE.GROUPS].listError,
      }
    },
  )

  useEffect(() => {
    //dispatch(getEntities(Client.ENTITY_TYPE.COLLECTORS));
    dispatch(getEntities(Client.ENTITY_TYPE.GROUPS))
  }, [dispatch])

  useEffect(() => {
    inputInitials.groups.options = [
      { name: '-', value: '-' },
      ...listGroups.map((group) => ({
        ...group,
        name: group.name,
        value: group.id,
      })),
    ]
    inputInitials.groups.options.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1,
    )
    setGroups(listGroups)
  }, [listGroups])

  useEffect(() => {
    inputInitials.users.options = [
      { name: '-', value: '-' },
      ...listCollectors.map((user) => ({
        ...user,
        name: `${user.surname} ${user.name} ${user.midname}`,
        value: user.id,
      })),
    ]
    inputInitials.users.options.sort((a, b) =>
      a.name > b.name ? 1 : a.name === b.name ? 0 : -1,
    )
    setCollectors(listCollectors)
  }, [listCollectors])

  //  console.log('listCollectors =>', listCollectors);
  //  console.log('listGroups =>', listGroups);
  //  console.log('inputInitials[users] =>', inputInitials['users']);
  //  console.log('inputInitials[groups] =>', inputInitials['groups']);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    console.log('id => ', id)
    setId(id || null)
    if (id) {
      //edit mode
      setPageReady(false)
      dispatch(getEntities(Client.ENTITY_TYPE.QUEUES, id))
    } else {
      //create mode
      const initialFormValues = {}
      Object.keys(inputInitials).forEach((key) => {
        if (inputInitials[key].type === 'order') {
          initialFormValues[`${key}-order`] = inputInitials[key].order.initial
          initialFormValues[`${key}-priority`] =
            inputInitials[key].priority.initial
        } else {
          initialFormValues[key] = inputInitials[key].initial
        }
      })
      setInitialFormValues(initialFormValues)
      setPageReady(true)
    }
  }, [dispatch, props.location])

  useEffect(() => {
    if (id && entityLoading === false && entityLoaded) {
      //edit mode
      const initialFormValues = {}

      //targeters
      initialFormValues.users =
        entityLoaded['users']?.length > 0 ? entityLoaded['users'] : []
      initialFormValues.groups =
        entityLoaded['groups']?.length > 0 ? entityLoaded['groups'] : []
      initialFormValues.queue_name =
        entityLoaded['queue_name'] !== undefined &&
        entityLoaded['queue_name'] !== null
          ? entityLoaded['queue_name']
          : ''
      initialFormValues.seq =
        entityLoaded['seq'] !== undefined && entityLoaded['seq'] !== null
          ? entityLoaded['seq']
          : ''

      //orders
      Object.keys(inputInitials)
        .filter((key) => inputInitials[key].type === 'order')
        .forEach((key) => {
          if (!entityLoaded[key] || !entityLoaded[key].order) {
            initialFormValues[`${key}-order`] = '-'
            initialFormValues[`${key}-priority`] = 0
          } else {
            initialFormValues[`${key}-order`] = parseInt(
              entityLoaded[key].order,
            )
            initialFormValues[`${key}-priority`] = parseInt(
              entityLoaded[key].priority,
            )
          }
        })

      //filters
      Object.keys(inputInitials)
        .filter((key) => inputInitials[key].type === 'filter')
        .forEach((key) => {
          if (!entityLoaded[key]) {
            initialFormValues[`${key}-enabled`] = false
            initialFormValues[key] = inputInitials[key].initial
          } else {
            initialFormValues[`${key}-enabled`] = true
            initialFormValues[key] = entityLoaded[key]
          }
        })

      console.log('edit mode, init fields =>', initialFormValues)
      console.log('entityLoaded =>', entityLoaded)
      setInitialFormValues(initialFormValues)
      setEntity(entityLoaded)
      setPageReady(true)
    }
  }, [entityLoaded, entityLoading, id])

  useEffect(() => {
    //dates
    if (!pageReady || !id || !entityLoaded) return

    if (entityLoaded.login_date?.length) {
      const fp_login_date = document.querySelector('#validation-login_date')
      if (fp_login_date) {
        fp_login_date._flatpickr.setDate(entityLoaded.login_date)
      }
    }

    if (entityLoaded.promise_date?.length) {
      const fp_promise_date = document.querySelector('#validation-promise_date')
      if (fp_promise_date) {
        fp_promise_date._flatpickr.setDate(entityLoaded.promise_date)
      }
    }
  }, [pageReady])

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialFormValues },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const data = {}
      if (selectedTimezone) {
        data.timezone = selectedTimezone
      }
      console.log('values', values)

      //targeters
      data.users = values['users'] //  === '-' ? [] : [values['users']];
      data.groups = values['groups'] // === '-' ? [] : [values['groups']];
      data.queue_name = values['queue_name']
      data.seq = parseInt(isNaN(values['seq'])) ? null : values['seq']

      //orders
      Object.keys(inputInitials)
        .filter((key) => inputInitials[key].type === 'order')
        .forEach((key) => {
          if (values[`${key}-order`] === '-') return
          data[key] = {
            order:
              values[`${key}-order`] === '-'
                ? null
                : parseInt(values[`${key}-order`]),
            priority: parseInt(values[`${key}-priority`]) || 0,
          }
        })

      //filters
      Object.keys(inputInitials)
        .filter((key) => inputInitials[key].type === 'filter')
        .forEach((key) => {
          if (values[`${key}-enabled`]) data[key] = values[key]
          else data[key] = null
        })

      console.log('data', data)
      if (id)
        dispatch(
          updateEntities({ id: entity.id, ...data }, Client.ENTITY_TYPE.QUEUES),
        )
      else dispatch(createEntities(data, Client.ENTITY_TYPE.QUEUES))
    },
  })

  const handleSelectChangeTimezone = (selectedValues) => {
    const selectedOptionValues = selectedValues.map((option) => option.value)
    if (selectedOptionValues.includes('selectAll')) {
      setSelectedTimezone(options.map((option) => option.value))
    } else {
      setSelectedTimezone(selectedOptionValues)
    }
  }

  const title = `${t('Queues')}: ${id ? t('Edit') : t('Create')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const loading = <Spinner color="primary">{t('Loading...')}</Spinner>
  const metaBar = <MetaBar backLink={'/queues'} entity={entity} />

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/queues'} title={title} />
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Form
              className="needs-validation mt-4"
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <h4>Распределить на</h4>
              {listUsersLoading || listGroupsLoading ? (
                <div className="w-100 p-3" style={{ height: 70 }}>
                  <Spinner color="primary" size="sm">
                    {t('Loading...')}
                  </Spinner>
                </div>
              ) : (
                <div className="mt-3 mb-3 d-flex flex-column gap-3">
                  {Object.keys(inputInitials)
                    .filter((d) => inputInitials[d].type === 'common')
                    .map((fieldKey) => {
                      return (
                        <div
                          key={fieldKey}
                          style={inputInitials[fieldKey].style}
                        >
                          <InputItem
                            description={inputInitials[fieldKey]}
                            validation={validation}
                            validationKey={fieldKey}
                          />
                        </div>
                      )
                    })}
                </div>
              )}

              <h4>Сортировка</h4>
              <div className="mt-3 mb-3">
                {Object.keys(inputInitials)
                  .filter((d) => inputInitials[d].type === 'order')
                  .map((fieldKey) => {
                    const keyOrder = `${fieldKey}-order`
                    const keyPriority = `${fieldKey}-priority`
                    return (
                      <Row key={fieldKey}>
                        <Col className="col-6" style={{ maxWidth: 300 }}>
                          <InputItem
                            description={inputInitials[fieldKey].order}
                            validation={validation}
                            validationKey={keyOrder}
                          />
                        </Col>
                        <Col className="col-6" style={{ maxWidth: 100 }}>
                          <InputItem
                            description={inputInitials[fieldKey].priority}
                            validation={validation}
                            validationKey={keyPriority}
                          />
                        </Col>
                      </Row>
                    )
                  })}
              </div>

              <h4>Фильтры</h4>
              <div className="mt-3 mb-3 d-flex flex-row gap-3">
                {Object.keys(inputInitials)
                  .filter(
                    (d) =>
                      inputInitials[d].type === 'filter' &&
                      inputInitials[d].dateRange,
                  )
                  .map((fieldKey) => {
                    return (
                      <InputItem
                        key={fieldKey}
                        description={inputInitials[fieldKey]}
                        validation={validation}
                        validationKey={fieldKey}
                        className="mb-4"
                      />
                    )
                  })}
              </div>
              <div className="mt-3 mb-3 w-100">
                {Object.keys(inputInitials)
                  .filter(
                    (d) =>
                      inputInitials[d].type === 'filter' &&
                      inputInitials[d].numberRange,
                  )
                  .map((fieldKey) => {
                    return (
                      <InputItem
                        key={fieldKey}
                        description={inputInitials[fieldKey]}
                        validation={validation}
                        validationKey={fieldKey}
                        className="mb-4"
                      />
                    )
                  })}
              </div>
              <div className="mt-3 mb-3 w-100">
                {Object.keys(inputInitials)
                  .filter(
                    (d) =>
                      inputInitials[d].type === 'filter' &&
                      inputInitials[d].isList,
                  )
                  .map((fieldKey) => {
                    return (
                      <InputItem
                        key={fieldKey}
                        description={inputInitials[fieldKey]}
                        validation={validation}
                        validationKey={fieldKey}
                        className="mb-4"
                      />
                    )
                  })}
              </div>
              <div className="mt-3 mb-3 w-100">
                {t('timezone')}
                <Select
                  isMulti
                  defaultValue={
                    entityLoaded?.timezone
                      ? entityLoaded.timezone.map((option) => ({
                          value: option,
                          label: option,
                        }))
                      : []
                  }
                  options={[selectAllOption, ...options]}
                  onChange={handleSelectChangeTimezone}
                />
              </div>

              {updateError && (
                <Alert color="warning">
                  <strong>{updateError}</strong>
                </Alert>
              )}
              {createError && (
                <Alert color="warning">
                  <strong>{createError}</strong>
                </Alert>
              )}
              {successful && (
                <Alert color="success">
                  <strong> {t('Operation success')} </strong>
                </Alert>
              )}

              {updateLoading || createLoading ? (
                loading
              ) : (
                <Button
                  className="text-nowrap"
                  color="primary"
                  style={{ backgroundColor: '#405189' }}
                  type="submit"
                >
                  {id ? t('Update') : t('Create')}
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}
