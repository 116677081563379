import {
  combineReducers,
  configureStore,
  PreloadedState
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './store/sagas'
import { api, opaqueRedirectMiddleware } from 'src/services/api'
import { authReducer } from 'src/services/keycloak'
import {
  TypedUseSelectorHook,
  useDispatch as useReactReduxDispatch,
  useSelector as useReactReduxSelector
} from 'react-redux'

// Front
import Layout from './store/layouts/reducer'

import products from './store/products/reducer'
import clients from './store/clients/reducer'
import profiles from './store/profiles/reducer'
import applications from './store/applications/reducer'
import loans from './store/loans/reducer'
import dialogues from './store/dialogues/reducer'
import productExtensions from './store/product-extensions/reducer'
import transactions from './store/transactions/reducer'
import externalServicesSettings from './store/external-services-settings/reducer'
import entities from './store/entities/reducer'
import verification from './store/verification/reducer'
import strategies from './store/strategies/reducer'

// Form advanced
import changeNumber from './store/formAdvanced/reducer'

import users from './store/users/reducer'
import roles from './store/roles/reducer'

const rootReducer = combineReducers({
  Layout,
  // @deprecated use RTKQ hooks instead of useSelector(state => state.Login...)
  Login: authReducer,
  products,
  profiles,
  dialogues,
  clients,
  applications,
  loans,
  transactions,
  productExtensions,
  externalServicesSettings,
  entities,
  changeNumber,
  users,
  roles,
  verification,
  strategies,
  [api.reducerPath]: api.reducer
})

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  const sagaMiddleware = createSagaMiddleware()
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      }).concat(sagaMiddleware, api.middleware, opaqueRedirectMiddleware)
  })
  sagaMiddleware.run(rootSaga)
  return store
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch: () => AppDispatch = useReactReduxDispatch
export const useSelector: TypedUseSelectorHook<RootState> =
  useReactReduxSelector
