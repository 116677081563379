import { useTranslation } from 'react-i18next'

import Client from '../../../client'
import Tabs from '../../../Components/Common/Tabs'
import { useSelector } from 'src/store'

const Related = ({ application }) => {
  const { t } = useTranslation()
  const userInfo = useSelector((state) => state.Login.userInfo)

  const items = []
  if (application.profile) {
    const { client, ...profile } = application.profile
    if (client) profile.client_id = client.id
    items.push({
      title: `${t('Profile')}`,
      entity: profile,
      entityId: profile.id,
      entityType: Client.ENTITY_TYPE.PROFILES,
      icon: null // 'ri-user-settings-line',
    })
  }

  if (application.profile && application.profile.client) {
    const phone = application.profile?.phone.startsWith('7')
      ? `+${application.profile?.phone}`
      : application.profile?.phone

    items.push({
      title: `${t('Client')}`,
      entity: {
        ...application.profile.client,
        phone,
        email: application.profile?.email,
        documents: application.documents
      },
      entityId: application.profile.client.id,
      entityType: Client.ENTITY_TYPE.CLIENTS,
      icon: null // 'ri-user-heart-line',
    })
  }

  if (application.status === application.documents)
    items.push({
      title: `${t('Documents')}`,
      entity: application.documents,
      entityId: application.id,
      entityType: Client.ENTITY_TYPE.DOCUMENTS,
      icon: null // 'ri-user-heart-line',
    })

  if (application.loan) {
    const {
      application_id,
      operations,
      loan_tranches,
      funds_transactions,
      product_copy,
      ...loan
    } = application.loan
    items.push({
      title: `${t('Loan')}`,
      entity: loan,
      entityId: loan.id,
      entityType: Client.ENTITY_TYPE.LOANS,
      icon: null //'ri-time-line',
    })
  }

  items.push({
    title: `${t('Autoverification_history')}`,
    entity: {},
    entityType: 'application_auto_verification',
    applicationId: application.id
  })

  // items.push({
  //   title: `${t('Verification_history')}`,
  //   entity: application.verificationHistory || {},
  //   entityType: 'application_verification_history',
  //   applicationId: application.id
  // })

  if (application.documentsSigned) {
    items.push({
      title: `${t('DocumentsSigned')}`,
      entityId: application.id,
      entity: application.documentsSigned,
      entityType: 'documents_signed'
    })
  }

  items.push({
    title: `${t('Block_client')}`,
    entity: application,
    entityType: 'block_client',
    application
  })

  items.push({
    title: `${t('applications_bki')}`,
    entity: {},
    entityType: 'applications_bki',
    applicationId: application.id
  })

  if (
    userInfo?.roles.includes('admin') ||
    userInfo?.roles?.includes('accountant')
  ) {
    items.push({
      title: `${t('UPRID')}`,
      entity: {},
      entityType: 'applications_uprid',
      applicationId: application.id
    })
  }

  if (new Date(application?.created_at) >= new Date('2024-07-18')) {
    items.push({
      title: `${t(`Identification history`)}`,
      entity: {},
      entityType: 'identification_history',
      application
    })
  }

  return <Tabs items={items} />
}

export default Related
