import { FC } from 'react'

//import Scss
import './assets/scss/themes.scss'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { setupStore } from './store'
import { routes } from './routes'

const store = setupStore()

const router = createBrowserRouter(routes)

export const App: FC = () => (
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
)
