import { useEffect, useState, useCallback } from 'react'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import List from './List'
import Client from 'src/client'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const fieldsDescription = {
  id: { isShortId: false },
  status: {},
  created_at: { isDateTime: true },
  begin_date: { isDateTime: true },
  closing_date: { isDateTime: true },
  repayment_date: { isDateTime: true },
}

const EntityContent = (props) => {
  const { entityId, style } = props
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [error, setError] = useState('')

  const fetchTranches = useCallback(() => {
    Client.getLoanData(Client.ENTITY_TYPE.LOAN_TRACNHES, entityId)
      .then((res) => {
        setList(res)
      })
      .catch((error) => setError(error))
  }, [entityId])

  const createTranch = () => {
    setError('')
    Client.createProlongation(entityId)
      .then((res) => {
        console.log('create tranch res', res)
        fetchTranches()
      })
      .catch((err) => {
        setError(err)
      })
  }

  useEffect(() => {
    if (props.visible) {
      fetchTranches()
    }
  }, [fetchTranches, props.visible])

  return (
    <div>
      {error && <DangerAlert error={error} />}
      <List list={list} fieldsDescription={fieldsDescription} style={style} />
      <Button
        className="btn-label mt-3"
        color="primary"
        style={{ backgroundColor: '#405189' }}
        onClick={createTranch}
      >
        <i className="ri-add-line label-icon align-middle fs-16 me-2"></i>
        {t('Create')}
      </Button>
    </div>
  )
}

export default EntityContent
